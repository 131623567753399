import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import TodayIcon from "@mui/icons-material/Today";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { Column, PaddingBox, Row, SpacedRow } from "./Scaffolds";
import {
  M3BodyLarge,
  M3BodyMedium,
  M3HeadlineSmall,
  M3LabelMedium,
  M3TitleLarge,
  M3TitleMedium,
} from "./TextStyles";
import theme from "../../utils/theme";
import { CloseButton, PrimaryButton, RemoveButton } from "./Buttons";
import React from "react";
import {
  TimeRangePicker,
  TextFieldNumber,
} from "./TextField";
import {
  convertDateToTime,
  convertTimeWithoutSeconds,
  getDate,
  getMonth,
  stringAvatar,
} from "../../utils/helper_functions";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { convertDateTimeToServerFormat, convertDateTimeToStringFormat } from "../../utils/time";
import { CustomAvatar } from "../Layout";

export const style = {
  p: 4,
}

export const styleDialog = {
  "& .MuiDialog-paper": {
    borderRadius: "20px",
  },
}


export function CancelInvitationModal({ open, onClose, onClickCancel, name, email }) {
  return (
    <Dialog
      sx={styleDialog}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <ErrorIcon sx={{ color: theme.colors.colorB3261E }} />
        <PaddingBox />
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Remove Invite
        </Typography>
        <PaddingBox />
        <M3BodyMedium>
          Are you sure you want to remove invite for {name} ({email})?
        </M3BodyMedium>
        <PaddingBox />
        <SpacedRow>
          <CloseButton onClick={onClose} text={'Cancel'} />
          <RemoveButton
            text="Remove"
            onClick={() => {
              onClickCancel();
              onClose();
            }}
          ></RemoveButton>
        </SpacedRow>
      </Box>
    </Dialog>
  );
}

export default function CancelShiftModal({ open, onClose, onClickCancel }) {
  return (
    <Dialog
      sx={styleDialog}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <ErrorIcon sx={{ color: theme.colors.colorB3261E }} />
        <PaddingBox />
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Are you sure you wish to cancel this shift?
        </Typography>
        <PaddingBox />
        <M3BodyMedium>
          You will not be able to undo this action. Shift will have to be
          re-created.
        </M3BodyMedium>
        <PaddingBox />
        <SpacedRow>
        <CloseButton onClick={onClose} text={'Close'} />
          <RemoveButton
            text="Cancel this Shift"
            onClick={() => {
              onClickCancel();
              onClose();
            }}
          ></RemoveButton>
        </SpacedRow>
      </Box>
    </Dialog>
  );
}
export function WarningDialogModal({ open, onClose, title, message }) {
  return (
    <Dialog
      sx={styleDialog}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, textAlign: 'center', maxWidth: 400 }}>
        <Row sx={{
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 2
        }}>
          <WarningIcon sx={{ color: theme.palette.warning.main, paddingRight: '4px' }} />
          <M3TitleLarge sx={{color: theme.palette.warning.main}}>{title}</M3TitleLarge>
        </Row>
        <M3BodyLarge>{message}</M3BodyLarge>
        <PaddingBox />
        <Button
          sx={{
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: 500,
            color: theme.palette.primary.onPrimary,
            backgroundColor: theme.palette.info.main
          }}
          onClick={onClose}
        >
          OK
        </Button>
      </Box>
    </Dialog>
  )
}

export function ErrorDialogModal({
  open,
  onClose,
  onClickCTA,
  title,
  content,
  ctaTitle,
}) {
  return (
    <Dialog
      sx={styleDialog}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <ErrorIcon sx={{ color: theme.colors.colorB3261E }} />
        <PaddingBox />
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <PaddingBox />
        <M3BodyMedium>{content}</M3BodyMedium>
        <PaddingBox />
        <SpacedRow>
          <Button
            sx={{
              textTransform: "none",
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.info.main,
            }}
            onClick={onClose}
          >
            Close
          </Button>
          <RemoveButton
            text={ctaTitle}
            onClick={() => {
              onClickCTA();
              onClose();
            }}
          ></RemoveButton>
        </SpacedRow>
      </Box>
    </Dialog>
  );
}

export function CreateShiftSuccessModal({ open, onClose, data }) {
  function getDate(shiftDate) {
    const date = new Date(shiftDate);
    return `${date.getDate()}`;
  }

  function getMonth(shiftDate) {
    const date = new Date(shiftDate);
    return `${date.toLocaleString("default", { month: "short" })}`;
  }

  function checkDifferenceMonth(firstMonth, secondMonth) {
    const valueFirstMonth = new Date(firstMonth).getMonth();
    const valueSecondMonth = new Date(secondMonth).getMonth();
    return valueFirstMonth !== valueSecondMonth;
  }

  function DayItem({ shiftDate }) {
    return (
      <div>
        <Box
          sx={{
            backgroundColor: theme.palette.tertiaryContainer.main,
            color: theme.palette.tertiaryContainer.onTertiary,
            borderRadius: 16,
            px: 1,
            marginLeft: 1,
          }}
        >
          <M3BodyMedium>{getDate(shiftDate)}</M3BodyMedium>
        </Box>
      </div>
    );
  }

  function MonthDayItem({ shiftDate }) {
    return (
      <div>
        <Row>
          <M3BodyMedium style={{ marginLeft: 2 }}>
            {getMonth(shiftDate)}
          </M3BodyMedium>
          <Box
            sx={{
              backgroundColor: theme.palette.tertiaryContainer.main,
              color: theme.palette.tertiaryContainer.onTertiary,
              borderRadius: 16,
              px: 1,
              marginLeft: 1
            }}
          >
            <M3BodyMedium>{getDate(shiftDate)}</M3BodyMedium>
          </Box>
        </Row>
      </div>
    )
  }
  return data.centre && (
    <Dialog
      sx={styleDialog}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} style={{ textAlign: 'center', width: 350 }}>
        <PaddingBox />
        <M3HeadlineSmall>Shift has been posted!</M3HeadlineSmall>
        <PaddingBox />
        <Column sx={{ alignItems: 'flex-start' }}>
          <M3TitleMedium>{data.centre?.name}</M3TitleMedium>
          <M3BodyMedium>{data.centre?.address.address}</M3BodyMedium>
          <PaddingBox />
          <Row>
            <TodayIcon />
            {data.shifts.length > 1 ? (
              data.shifts.map((shift, index) => (
                <div>
                  {index !== 0 ? (
                    checkDifferenceMonth(
                      shift.shift_date,
                      data.shifts[index - 1].shift_date
                    ) ? (
                      <MonthDayItem shiftDate={shift.shift_date} />
                    ) : (
                      <DayItem shiftDate={shift.shift_date} />
                    )
                  ) : (
                    <MonthDayItem shiftDate={shift.shift_date} />
                  )}
                </div>
              ))
            ) : (
              <MonthDayItem shiftDate={data.shifts[0].shift_date} />
            )}
          </Row>
          <Row>
            <QueryBuilderIcon />
            <M3BodyMedium style={{ marginLeft: 2 }}>
              {convertTimeWithoutSeconds(data.shifts[0].shift_start) +
                ' - ' +
                convertTimeWithoutSeconds(data.shifts[0].shift_end)}
            </M3BodyMedium>
          </Row>
          <PaddingBox />
          <Box
            sx={{
              backgroundColor: theme.palette.tertiaryContainer.main,
              color: theme.palette.tertiaryContainer.onTertiary,
              textAlign: 'start',
              flexGrow: 1,
              width: '95%'
            }}
            p={1}
          >
            <Column>
              <M3LabelMedium>Main contact</M3LabelMedium>
              <M3BodyLarge>{data.centre.contact_name}</M3BodyLarge>
              <M3BodyMedium>{data.centre.contact_email}</M3BodyMedium>
            </Column>
          </Box>
        </Column>
        <PaddingBox />
        <CloseButton onClick={onClose} text={'Close'} />
      </Box>
    </Dialog>
  )
}
export function SuccessDialogModel({ open, onClose, title, description }) {
  return (
    <Dialog
      open={open}
      PaperProps={{ sx: { borderRadius: '20px' } }}
      onClose={onClose}
    >
      <DialogTitle id="alert-dialog-title">
        <div>
          <CheckCircleOutlineRoundedIcon sx={{ fill: 'green' }} />
          <div sx={{ margin: 0 }}>{title}</div>
        </div>
      </DialogTitle>
      <DialogContent>
      <div dangerouslySetInnerHTML={{ __html: description }} />
      </DialogContent>
      <DialogActions>
        <CloseButton onClick={onClose} text={'Close'} />
      </DialogActions>
    </Dialog>
  )
}


export function AdjustBillableHoursModal({
  open,
  onClose,
  onSave,
  shift,
  educatorFilled,
}) {
  const [startTime, setStartTime] = React.useState(null);
  const [endTime, setEndTime] = React.useState(null);

  function setTimeRange(newValue) {
    setStartTime(convertDateTimeToServerFormat(newValue[0]));
    setEndTime(convertDateTimeToServerFormat(newValue[1]));
  }
  const [breakTime, setBreakTime] = React.useState(null);

  function handleBreakTime(e) {
    if (Number(e.target.value) > 999) {
      setBreakTime(999);
    } else if (Number(e.target.value) < 0) {
      setBreakTime(0);
    } else {
      setBreakTime(Number(e.target.value));
    }
  }

  function handleAdjustHours() {
    onSave({
      check_in: convertDateTimeToStringFormat(
        startTime ?? convertDateToTime(shift.start_time),
        shift.shift_date
      ),
      check_out: convertDateTimeToStringFormat(
        endTime ?? convertDateToTime(shift.end_time),
        shift.shift_date
      ),
      break_time: breakTime,
    });
  }

  function validateValue() {
    return (
      (startTime !== null && endTime !== null) ||
      breakTime !== shift.break_minute
    );
  }

  React.useEffect(() => {
    setStartTime(shift.adjusted_start_time != null ? shift.adjusted_start_time : shift.shift_start);
    setEndTime(shift.adjusted_end_time != null ? shift.adjusted_end_time : shift.shift_end);
    setBreakTime(shift.break_minute);
  }, [open, shift.break_minute, shift.adjusted_start_time, shift.adjusted_end_time, shift.shift_start, shift.shift_end]);

  return shift !== null ? (
    <Dialog sx={styleDialog} open={open} onClose={onClose}>
      <Box sx={style}>
        <PaddingBox />
        <M3HeadlineSmall style={{ fontWeight: '900' }}>
          Adjust billable hours
        </M3HeadlineSmall>
        <M3BodyMedium>Adjust the hours the educator worked for</M3BodyMedium>
        <PaddingBox />
        <Column sx={{ alignItems: 'flex-start', marginTop: 2 }}>
          <Row>
            <CustomAvatar
              {...stringAvatar(shift.centre.name)}
              src={
                shift.centre.images !== null && shift.centre.images.length > 0
                  ? shift.centre.images[0]
                  : null
              }
            />
            <PaddingBox />
            <Column>
              <M3TitleMedium>{shift.centre.name}</M3TitleMedium>
              <M3BodyMedium>{shift.centre.address.address}</M3BodyMedium>
            </Column>
          </Row>
          <PaddingBox />
          <Row sx={{ display: 'flex', width: '100%' }}>
            <TodayIcon />
            <M3BodyMedium style={{ marginLeft: 2 }}>
              {getMonth(shift.shift_date)}
            </M3BodyMedium>
            <Box
              sx={{
                backgroundColor: theme.palette.tertiaryContainer.main,
                color: theme.palette.tertiaryContainer.onTertiary,
                borderRadius: 16,
                px: 1,
                marginLeft: 1
              }}
            >
              <M3BodyMedium>{getDate(shift.shift_date)}</M3BodyMedium>
            </Box>
            <Box sx={{ flex: 1 }} />
            <QueryBuilderIcon />
            <M3BodyMedium style={{ marginLeft: 2 }}>
              {convertTimeWithoutSeconds(shift.shift_start) +
                ' - ' +
                convertTimeWithoutSeconds(shift.shift_end)}
            </M3BodyMedium>
          </Row>
          <PaddingBox />
          {educatorFilled != null ? (
            <M3BodyMedium>
              Filled by: {educatorFilled.first_name ?? ''}{' '}
              {educatorFilled.last_name ?? ''}
            </M3BodyMedium>
          ) : null}

          <PaddingBox />
          <TimeRangePicker
            startTime={startTime ?? shift.shift_start}
            endTime={endTime ?? shift.shift_end}
            setData={setTimeRange}
          />
          <PaddingBox />
          <TextFieldNumber
            value={breakTime}
            onChange={handleBreakTime}
            label={'Minutes of break (unpaid)'}
          />
        </Column>
        <PaddingBox />
        <SpacedRow>
          <CloseButton onClick={onClose} text={'Close'} />
          <PrimaryButton
            text="Save"
            isDisabled={!validateValue()}
            onClick={handleAdjustHours}
          />
        </SpacedRow>
      </Box>
    </Dialog>
  ) : null
}
