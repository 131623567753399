import React, { useCallback, useEffect, useState } from 'react'
import { SearchBox } from '../../reusable/TextField'
import { get, getFileAsBlob, put } from '../../../utils/api'
import { debounce } from '../../../utils/helper_functions'
import { ApplicationItem, ApplicationSkeleton } from './components/application_item'
import {
  PaddingBox,
  CustomTab,
  CustomTabs,
  SpacedRow,
} from "../../reusable/Scaffolds";
import MemberList from './components/MemberTab'
import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import _ from 'lodash'
import { ApplicationReviewModal } from './components/application_review_modal'
import { Box } from '@mui/material'
import { M3TitleLarge } from '../../reusable/TextStyles'
import theme from "../../../utils/theme";

const ApplicationsManage = ({setAlert}) => {
  const [applications, setApplications] = useState([])
  const [searchText, setSearchText] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [selectedApplication, setSelectedApplication] = useState(null)
  const [currentTab, setCurrentTab] = React.useState(0);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([])
  
  const baseUrl = '/file-content?filePath='

  const fetchApplications = useCallback(
    async (searchText) => {
      const params = {
        name_text: searchText ?? ''
      }
      setIsFetching(true)
      const convertedParams = Object.keys(params)
        .map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        })
        .join('&')
      get(`/admin/applications${convertedParams ? '?' + convertedParams : ''}`)
        .then((data) => {
          setIsFetching(false)
          const sortedData = _.orderBy(data, ['unseen'], ['desc'])
          setApplications(sortedData)
        })
        .catch((error) => {
          setAlert({ type: 'error', message: error.message })
          setIsFetching(false)
        })
    },
    [setAlert]
  )
  
  const calculatePageSize = () => {
    const itemHeight = 100;
    const screenHeight = window.innerHeight;
    const size = Math.floor(screenHeight / itemHeight);
    return size < 10? 10: size;
  };
  
  const fetchMembers = useCallback(async (pageIndex, hasMoreItem, current_search_text) => {
    const currentPage = pageIndex || page;
    const currentHasMore = hasMoreItem || hasMore;
    const currentText = current_search_text ?? searchText;
    if (loading || !currentHasMore) return;
    setLoading(true);
    try {
      const pageSize = calculatePageSize();
      const response = await get(`/admin/onboarding?page=${currentPage}&page_size=${pageSize}&search_text=${currentText || ''}`);
      response.forEach(async (member) => {
        member.avatar = await getFileAsBlob(`${baseUrl}${member.photo_url}`);
      });
      if(currentPage === 1) {
        setMembers(response);
      } else {
        setMembers(prevMembers => [...prevMembers, ...response]);
      }
      setHasMore(response.length > 0);
    } catch (error) {
      console.error('Failed to fetch members:', error);
    } finally {
      setLoading(false);
    }
  }, [loading, hasMore, page, searchText]);

  const debouncedFetchApplications = (searchText) => {
    if(currentTab === 0) {
      debounce(loadMembers(searchText), 200)
    } else {
      debounce(fetchApplications(searchText), 200)
    }
  }

  const onClickItem = (application) => {
    setSelectedApplication(application)
    if (application.unseen)
    updateReadStatus(application.id)
  }

  const loadMembers = (searchText) => {
    setPage(1)
    setHasMore(true)
    fetchMembers(1, true, searchText)
  }

  const updateReadStatus = (id) => {
    put(`/admin/applications/${id}/seen`)
      .then((data) => {
        setApplications((prev) => {
          const updatedApplications = prev.map((application) => {
            if (application.id === id) {
              return { ...application, unseen: false }
            }
            return application
          })
          return updatedApplications
        })
      })
      .catch((error) => {
       setAlert({ type: 'error', message: error.message })
      })
  }
  
  const handleTabChange = (event, newValue) => {
    setSearchText('')
    debounce(setCurrentTab(newValue), 500);
  };

  const closeReviewModal = () => {
    setSelectedApplication(null)
    fetchApplications()
  }
  useEffect(() => {
    if(currentTab === 1) {
      fetchApplications()
    }
  }, [currentTab, fetchApplications])

  
  useEffect(() => {
    if(currentTab === 0) {
      fetchMembers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, currentTab]);

  return (
    <div>
      <ApplicationReviewModal open={selectedApplication !== null} closeModal={closeReviewModal} people={selectedApplication} setAlert={setAlert} />
      <SpacedRow>
        <M3TitleLarge>Applications</M3TitleLarge>
      </SpacedRow>
      <Box sx={{
          borderBottom: 1,
          borderColor: theme.palette.outline.main
        }}>
          <CustomTabs
            variant="fullWidth"
            value={currentTab}
            onChange={handleTabChange}
            indicatorColor="info"
          >
            <CustomTab
              key={0}
              label={"Members"}
              iconPosition="end"
            />
            <CustomTab
              key={1}
              label={"App registrations"}
              iconPosition="end"
            />
          </CustomTabs>
      </Box>

      <PaddingBox />
      
      <Box sx={{ padding: '20px 0' }}>
        <SearchBox
          placeholder={'Search for an educator'}
          onChange={(e) => {
            setSearchText(e.target.value)
            debouncedFetchApplications(e.target.value)
          }}
          value={searchText}
        ></SearchBox>
      </Box>
      
      {currentTab === 0 && <MemberList members={members} loading={loading} hasMore={hasMore} page={page} setPage={setPage} onClickItem={onClickItem}  />}
      {
      currentTab === 1 && (
      isFetching ? (
        <ApplicationSkeleton></ApplicationSkeleton>
      ) :
      applications.length === 0 ? (
        <Box>There are no applications</Box>
      ) : (
        <AutoSizer style={{ height: '60vh' }}>
          {({ height, width }) => {
            return (
              <List
                className="List"
                height={height}
                itemCount={applications ? applications.length : 0}
                itemSize={110}
                width={width}
              >
                {({ index, style }) => {
                return (
                  <ApplicationItem
                    style={style}
                    key={index}
                    people={applications[index]}
                    onClick={()=> onClickItem(applications[index])}
                  ></ApplicationItem>
                )
                }}
              </List>
            )
          }}
        </AutoSizer>
      )
    )
    }
    </div>
  )
}

export default ApplicationsManage
