
import React, { useEffect } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {post} from '../../utils/api';
import { CircularProgress, Container } from '@mui/material';

const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY

function StripeWrapperPage() {
  useEffect(() => {
    initStripe()
  }, []);
  async function initStripe() {
    const stripe = await loadStripe(stripePublishableKey);
    const res = await post('/create-verification-session');
    const { client_secret } = res;
    const { error } = await stripe.verifyIdentity(client_secret);
    if (error) {
      console.log('[error]', error);
      window.flutter_inappwebview.callHandler('messageHandler', 'ERROR');
    } else {
      window.flutter_inappwebview.callHandler('messageHandler', 'DONE');
      console.log('Verification submitted!');
    }
  }

  return (
    <Container>
      <CircularProgress 
        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} 
      />
    </Container>
  );
}

export default StripeWrapperPage;