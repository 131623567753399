import React, { useContext, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { AddButton } from '../../reusable/Buttons'
import { CustomTab, CustomTabs, SpacedRow } from '../../reusable/Scaffolds'
import { debounce } from '../../../utils/helper_functions'
import { SuccessDialogModel } from '../../reusable/Modal'
import ManageLoginModal from './components/logins_create_edit_modal'
import { get, post, put } from '../../../utils/api'
import { OrgContext, UserContext } from '../../../utils/context'
import { permissionList } from '../../../utils/constants'
import ActiveTab from './ActiveTab'
import DeactiveTab from './DeactivatedTab'
import { isOwner } from '../../../utils/auth'
import { M3TitleLarge } from '../../reusable/TextStyles'
import theme from '../../../utils/theme'

const activeTab = {
  name: 'Active',
  value: 0
}
const inactiveTab = {
  name: 'Inactive',
  value: 1
}

const Logins = ({ setAlert }) => {
  const [currentTab, setCurrentTab] = React.useState(activeTab.value)
  const [centreList, setCentreList] = React.useState([])
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const [successTitle, setSuccessTitle] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [selectedUser, setSelectedUser] = React.useState(null)
  const [forceUpdate, setForceUpdate] = useState(0)
  const [organisationOptions, setOrganisationOptions] = React.useState([]);

  const forceUpdateHandler = () => {
    setForceUpdate(forceUpdate + 1)
  }
  const handleTabChange = (event, newValue) => {
    debounce(setCurrentTab(newValue), 500)
  }
  const org = useContext(OrgContext)
  const org_id = org ? org.organisation_id : null
  // const [orgId, setOrgId] = useState(org ? org.organisation_id : null);
  const userInfo = useContext(UserContext)

  const handleCreateLogin = async ({ formData }) => {
    try {
      const orgId = formData?.organisation_id || org_id
      await post(`/manage/organisations/${orgId}/staff`, formData)
      setOpenLoginModal(false)
      setShowSuccess(true)
      forceUpdateHandler()
    } catch (e) {
      setAlert(e.toString(), 'error')
    }
  }

  const handleEditLogin = async ({ userId, formData }) => {
    try {
      const orgId = formData?.organisation_id || org_id
      await put(`/manage/organisations/${orgId}/staff/${userId}`, formData)
      setOpenLoginModal(false)
      setAlert('Login editted successfully', 'success')
      forceUpdateHandler()
    } catch (e) {
      setAlert(e.toString(), 'error')
    }
  }

  const handlePasswordReset = async (user) => {
    try {
      await post(`/password/reset/request`, { email: user.email })
      setAlert('Password reset sent successfully', 'success')
      setOpenLoginModal(false)
    } catch (e) {
      setAlert(e.toString(), 'error')
    }
  }

  const createLogin = () => {
    setOpenLoginModal(true)
    setSelectedUser(null)
  }

  const fetchListCentre = React.useCallback(async (orgParam) => {
    try {
      const res = await get(`/manage/organisations/${orgParam || org_id}/centres`)
      setCentreList(res)
    } catch (e) {}
  }, [org_id])

  const openEditLoginModal = (user) => {
    setOpenLoginModal(true)
    setSelectedUser(user)
  }

  const fetchOrganisations = React.useCallback(async () => {
    const res = await get(`/admin/organisations`);
    const organisationList = res.map(org => ({ id: org.id, name: org.name }))
    setOrganisationOptions(organisationList)
  }, []);

  useEffect(() => {
    if(!userInfo.is_quickcare_admin) {
      fetchListCentre()
    } else {
      fetchOrganisations()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Box>
        <SpacedRow>
          <M3TitleLarge>Manage Logins</M3TitleLarge>
            <AddButton text="Create login" onClick={createLogin} />
        </SpacedRow>
        <Box sx={{ borderBottom: 1, borderColor: theme.palette.outline.main }}>
          <CustomTabs
            variant="fullWidth"
            value={currentTab}
            onChange={handleTabChange}
            indicatorColor="info"
          >
            <CustomTab label={activeTab.name} />
            <CustomTab label={inactiveTab.name} />
          </CustomTabs>
        </Box>

        {currentTab === activeTab.value ? (
          <ActiveTab
            update={forceUpdate}
            org_id={org_id}
            onItemClick={(user) => openEditLoginModal(user)}
          />
        ) : (
          <DeactiveTab
            org_id={org_id}
            update={forceUpdate}
            onItemClick={(user) => openEditLoginModal(user)}
          />
        )}
        <ManageLoginModal
          open={openLoginModal}
          centreList={centreList}
          fetchListCentre={fetchListCentre}
          isOwner={userInfo?.is_quickcare_admin || isOwner(org)}
          isAdmin={userInfo?.is_quickcare_admin}
          permissionList={permissionList}
          isMe={userInfo?.id === selectedUser?.id}
          organisationOptions={organisationOptions}
          onClick={(formData) => {
            if (selectedUser && selectedUser.id) {
              handleEditLogin({ userId: selectedUser.id, ...formData })
            } else {
              handleCreateLogin(formData)
            }
          }}
          onCancel={() => {
            setOpenLoginModal(false)
            setSelectedUser(null)
          }}
          onPasswordReset={(user) => {
            handlePasswordReset(user)
          }}
          user={selectedUser}
          isEditModal={!!selectedUser}
        />
        <SuccessDialogModel
          onClose={() => {
            setSuccessTitle(null)
            setShowSuccess(false)
          }}
          open={showSuccess}
          title={successTitle ?? 'New login created'}
          description={
            'An email and SMS has been sent to the user informing them of this invitation.'
          }
        />
      </Box>
    </div>
  )
}
export default Logins
