import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom'
import { ThemeProvider } from '@mui/system'
import theme from './utils/theme'
import { getLocalStorageToken, removeLocalStorageToken, get } from './utils/api'
import { OrgContext, UserContext } from './utils/context'
import { useParams } from 'react-router-dom'

import Login from './components/pages/Login'
import Organisations from './components/pages/Organisation'
import OrganisationDetail from './components/pages/OrganisationDetail'
import OrgCentreForm from './components/forms/OrgCentreForm'
import CentreDetail from './components/pages/CentreDetail'
import Shifts from './components/pages/Shifts'
import ResetForm from './components/forms/ResetPassword'
import SignupForm from './components/forms/SignupForm'
import NotFound from './components/pages/NotFound'
import Layout from './components/Layout'
import Educators from './components/pages/Educators'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import Logins from './components/pages/Logins'
import ApplicationsManage from './components/pages/Applications'
import MemberDetail from './components/pages/Applications/components/MemberDetail'
import { Alert } from '@mui/material'
import ShiftDetail from './components/pages/ShiftDetail'
import EducatorDetail from './components/pages/EducatorDetail'
import EducatorAccount from './components/pages/EducatorAccount'
import Loading from './components/reusable/Loading'
import SignupSuccess from './components/pages/SignUpSuccess'
import ErrorShiftPage from './components/pages/ShiftDetail/error_page'
import { DownloadPage } from './components/pages/InvitePage'
import VerifyEmployerPage from './components/pages/Employer';
import StripeWrapperPage from './components/pages/StripeWrapperPage'

function App() {
  const [token, setToken] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isAdmin, setIsAdmin] = useState(null)
  const [userOrg, setUserOrg] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)
  const [alert, setAlert] = useState(null)
  useEffect(() => {
    const savedToken = getLocalStorageToken()
    if (savedToken) {
      onLoginSuccess(savedToken)
    } else {
      setLoading(false)
    }
  }, [])

  const setAlertAction = (message, severity) => {
    setAlert({ message, severity })
    setTimeout(() => {
      setAlert(null)
    }, 3000)
  }
  const onLoginSuccess = (t) => {
    setToken(t)
    get('/me')
      .then((userData) => {
        setLoading(false)
        setIsAdmin(userData.is_quickcare_admin)
        setCurrentUser(userData)
        if (userData.organisations.length > 0) {
          setUserOrg(userData.organisations[0])
        }
      })
      .catch(() => {
        setToken(null)
        setLoading(false)
      })
  }

  const handleLogout = () => {
    setCurrentUser(null)
    setUserOrg(null)
    removeLocalStorageToken()
    setToken(null)
  }

  function ResetWrapper() {
    const { code } = useParams()
    return <ResetForm code={code} />
  }

  function SignupWrapper() {
    const { secret } = useParams()
    return <SignupForm secret={secret} />
  }

  function SignupSuccessWrapper() {
    const { orgInvite } = useParams()
    return <SignupSuccess orgInvite={orgInvite} />
  }

  function LoginWrapper() {
    return <Login onLoginSuccess={onLoginSuccess} />
  }

  function RootElement() {
    if (isAdmin)
      return <Educators setAlert={setAlertAction} currentUser={currentUser} />
    if (userOrg && userOrg.access !== 'educator') return <Shifts setAlert={setAlertAction} />
    if (loading || !userOrg) {
      return <Loading />
    }
    return <EducatorAccount onLogout={handleLogout} />
  }

  return (
    <ThemeProvider theme={theme}>
      <UserContext.Provider value={currentUser}>
        <OrgContext.Provider value={userOrg}>
          <Router>
            <Layout
              isLoggedIn={!!token}
              isAdmin={isAdmin}
              onLogout={handleLogout}
              currentRole={userOrg ? userOrg.access : null}
              loading={loading}
              currentUser={currentUser}
            >
              <Routes>
                {token ? (
                  <>
                    <Route path="/" element={<RootElement />} />
                    <Route
                      path="/shifts"
                      element={
                        <Shifts setAlert={setAlertAction} />
                      }
                    />
                    <Route
                      path="/shifts/:shift_id"
                      element={
                        <ShiftDetail
                          currentUser={currentUser}
                          setAlert={setAlertAction}
                        />
                      }
                    />
                    <Route
                      path="/educators"
                      element={
                        <Educators
                          setAlert={setAlertAction}
                          currentUser={currentUser}
                        />
                      }
                    />
                    <Route path="/educators/:id" element={<EducatorDetail currentUser={currentUser} />} />
                    <Route
                      path="/logins-manage"
                      element={<Logins setAlert={setAlertAction} />}
                    />
                    <Route
                      path="/organisation"
                      element={<Organisations setAlert={setAlertAction} />}
                    />
                    <Route
                      path="/organisations/:org_id"
                      element={<OrganisationDetail />}
                    />
                    <Route
                      path="/organisations/:org_id/add-centre"
                      element={<OrgCentreForm />}
                    />
                    <Route
                      path="/organisations/:org_id/centres/:centre_id/edit"
                      element={<OrgCentreForm />}
                    />
                    <Route
                      path="/organisations/:org_id/centres/:centre_id"
                      element={<CentreDetail />}
                    />
                    <Route
                      path="/applications"
                      element={<ApplicationsManage setAlert={setAlertAction} />}
                    />
                    <Route
                      path="/applications/:member_id"
                      element={<MemberDetail setAlert={setAlertAction} />}
                    />
                    <Route 
                      path="/shifts/error" 
                      element={<ErrorShiftPage />} />
                    <Route path="/login" element={<Navigate to="/" />} />{' '}
                    {/* Logged in users redirect */}
                  </>
                ) : (
                  <>
                    <Route path="/login" element={<LoginWrapper />} />
                    <Route path="*" element={<Navigate to="/login" />} />
                  </>
                )}
                <Route path="/signup-success/:orgInvite" element={<SignupSuccessWrapper />} />
                <Route path="/signup/:secret" element={<SignupWrapper />} />
                <Route path="/reset/:code" element={<ResetWrapper />} />
                <Route path="/download" element={<DownloadPage />} />
                <Route path="/stripe" element={<StripeWrapperPage />} />
                <Route path="/verify-employer" element={<VerifyEmployerPage />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Layout>
            {alert ? (
              <Alert
                style={{
                  position: 'fixed',
                  bottom: 20,
                  right: 20,
                  zIndex: 100000
                }}
                severity={alert.severity ? alert.severity : 'success'}
              >
                {alert.message}
              </Alert>
            ) : null}
          </Router>
        </OrgContext.Provider>
      </UserContext.Provider>
    </ThemeProvider>
  )
}

export default App
