import React, { useContext } from "react";
import { Box, Button, Modal } from "@mui/material";
import { modalBoxStyles } from "../reusable/ModalStyles";
import { PaddingBox, SpacedRow } from "../reusable/Scaffolds";
import {
  M3BodyMedium,
  M3HeadlineSmall,
  M3TitleMedium,
} from "../reusable/TextStyles";
import { post } from "../../utils/api";
import { OrgContext } from "../../utils/context";
import { formatTimestampToddmmyyyy } from "../../utils/time";
import { stringAvatar } from "../../utils/helper_functions";
import { CustomAvatar } from "../Layout";
import { CloseButton } from "../reusable/Buttons";
const RemoveBlacklistForm = ({
  open,
  setOpen,
  educatorInfo,
  setAlert,
  refreshShift,
  blacklistedInfo,
}) => {
  const org = useContext(OrgContext);
  const org_id = org ? org.organisation_id : null;

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemoveBlacklist = () => {
    post(`/manage/organisations/${org_id}/educators/black_listed/remove`, {
      educator_id: educatorInfo.id,
    })
      .then((res) => {
        handleClose();
        refreshShift();
        setAlert(res.message);
      })
      .catch((error) => {
        handleClose();
        setAlert(error.message, "error");
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 999 }}
    >
      <Box sx={modalBoxStyles}>
        <CustomAvatar
          {...stringAvatar(
            educatorInfo.first_name + " " + educatorInfo.last_name
          )}
          src={educatorInfo.avatar_url}
          sx={{ width: 80, height: 80 }}
        />
        <PaddingBox />

        <M3HeadlineSmall>
          {educatorInfo.first_name}{" "}
          {educatorInfo.prefered_name && `(${educatorInfo.prefered_name}) `}
          {educatorInfo.last_name}
        </M3HeadlineSmall>
        <PaddingBox />

        <M3TitleMedium>Blacklist Reason</M3TitleMedium>
        <M3BodyMedium>{blacklistedInfo.reason ?? ""}</M3BodyMedium>
        <PaddingBox />

        <M3TitleMedium>Blocked by</M3TitleMedium>
        <M3BodyMedium>
          {blacklistedInfo.blocker_first_name ?? ""}{" "}
          {blacklistedInfo.blocker_last_name ?? ""}
        </M3BodyMedium>
        <PaddingBox />

        <M3TitleMedium>Blocked on</M3TitleMedium>
        <M3BodyMedium>
          {formatTimestampToddmmyyyy(blacklistedInfo.created_at ?? "")}
        </M3BodyMedium>
        <PaddingBox />

        <PaddingBox />

        <SpacedRow>
          <CloseButton onClick={handleClose} text={"Cancel"} />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleRemoveBlacklist}
          >
            Remove Blacklist
          </Button>
        </SpacedRow>
      </Box>
    </Modal>
  );
};

export default RemoveBlacklistForm;
