// src/components/forms/VerifyEmployerForm.js
import React, { useState } from 'react';
import { TextField, Button, Typography, Box, CircularProgress } from '@mui/material';
import { post } from '../../utils/api';
import { DatePickerTextField } from "../reusable/TextField"
import { PaddingBox } from '../reusable/Scaffolds';

const VerifyEmployerForm = () => {
  const [data, setData] = useState([]);
  const [lastName, setLastName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [referenceNumber, setReferenceNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      lastName,
      birthday,
      referenceNumber,
    };
    setData([...data, formData]);
    // clear form
    setLastName('');
    setBirthday('');
    setReferenceNumber('');
  }
  
  const verifyEmployer = async () => {
    setIsLoading(true)
    try {
      console.log(data)
      const response = await post('/admin/verifyEmployer', {employers: data});
      
      const updatedData = data.map((item, index) => {
        return { ...item, result: response[index] };
      });
      setData(updatedData);
      setError('');
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="rgba(255, 255, 255, 0.8)"
          zIndex={9999}
        >
          <CircularProgress />
        </Box>
      )}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          fullWidth
          required
        />
        <PaddingBox />
        <DatePickerTextField
          id="Birthday"
          label="Birthday"
          value={birthday}
          onChange={(e) => setBirthday(e.target.value)}
          renderInput={(params) => <TextField {...params} fullWidth required />}
          inputFormat="DD/MM/YYYY"
        />
        <PaddingBox />
        <TextField
          label="Reference Number"
          value={referenceNumber}
          onChange={(e) => setReferenceNumber(e.target.value)}
          fullWidth
          required
        />
        <PaddingBox />
        <Button type="submit" variant="contained" color="primary">
          Add Employer
        </Button>
        {error && <Typography color="error">{error}</Typography>}
      </form>
      <Box mt={4}>
        <Typography variant="h6">Employer Data</Typography>
        <Box sx={{ overflowX: 'auto' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2' }}>Last Name</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2' }}>Birthday</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2' }}>Reference Number</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2' }}>Result</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.lastName}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.birthday}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.referenceNumber}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.result}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
        <PaddingBox />
        <Button variant="contained" color="secondary" onClick={verifyEmployer}>
          Verify Employers
        </Button>
      </Box>
    </>
  );
}
export default VerifyEmployerForm;