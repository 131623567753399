import {
  Box,
  Button,
  MenuItem,
  Typography,
  Dialog,
  Autocomplete,
  TextField
} from '@mui/material'
import { M3BodyMedium } from '../../../reusable/TextStyles'
import theme from '../../../../utils/theme'
import React, { useCallback, useEffect } from 'react'
import {
  convertYearMonthDay,
  upperCaseFirstLetter
} from '../../../../utils/helper_functions'
import { bonusList } from '../../../../utils/constants'
import {
  compareTimePickerValues,
  compareTwoDates,
  get2MonthsLaterDate,
  isDateBeforeCurrentDate,
  isEndDateIsTwoMonthsLaterAfterStartDate,
  isValidDate
} from '../../../../utils/time'
import { get } from '../../../../utils/api'
import { convertDateTimeToServerFormat } from '../../../../utils/time'
import { style, styleDialog } from '../../../reusable/Modal'
import {
  CommonTextField,
  DatePickerTextField,
  TimeRangePicker
} from '../../../reusable/TextField'
import { CloseButton, DropDownButton } from '../../../reusable/Buttons'
import { PaddingBox, Row, SpacedRow } from '../../../reusable/Scaffolds'
import _ from 'lodash'
export function CreateShiftModal({
  open,
  onCancel,
  onClickCreate,
  centres,
  qualifications,
  orgId,
  orgName,
}) {
  const today = new Date()
  const [educatorPool, setEducatorPool] = React.useState('')
  const [qualificationOptions, setQualificationOptions] = React.useState(qualifications)
  const [roleOptions, setRoleOptions] = React.useState([])
  const [roleDisable, setRoleDisable] = React.useState(false)
  const [currentRole, setCurrentRole] = React.useState(null)
  const [assignOptions, setAssignOptions] = React.useState([])
  const [selectedEducator, setSelectedEducator] = React.useState(null)
  const [allOrgEducators, setAllOrgEducators] = React.useState([]);
  const [individualAssigned, setIndividualAssigned] = React.useState(false)
  const changeEducatorPool = (event) => {
    setEducatorPool(event.target.value)
    setIndividualAssigned(event.target.value === 'individual-assigned')
  }
  const [bonus, setBonus] = React.useState('')
  const changeBonus = (event) => {
    setBonus(event.target.value)
  }
  const [centre, setCentre] = React.useState('')

  const rolesData = {
    ASSISTANT: 'assistant',
    LEADER: 'leader'
  }

  const qualificationData = {
    ANY: -1,
    CERT3: 1,
    DIPLOMA: 3,
    ECT: 5
  }

  const fetchAssignOption = useCallback(
    async (centre_id) => {
      try {
        const res = await get(
          `/manage/organisation/${orgId}/assignment_group_options`,
          null,
          { centre_id }
        )
        setAssignOptions(res)
      } catch (error) { }
    },
    [orgId]
  )

  const fetchRoleOption = useCallback(
    async () => {
      try {
        const res = await get(
          `/manage/roles`
        )
        setRoleOptions(res.roleOptions)
      } catch (error) { }
    },
    []
  )

  const changeCentre = (event) => {
    fetchAssignOption(event.target.value)
    setCentre(event.target.value)
  }

  const changeRole = (event) => {(
    setCurrentRole(event.target.value))
    if(event.target.value === rolesData.ASSISTANT) {
      setQualificationOptions(qualifications.filter(item => item.id === qualificationData.CERT3 
        || item.id === qualificationData.DIPLOMA
        || item.id === qualificationData.ANY
      ))
      if(qualification === qualificationData.ECT) {
        setQualification(qualificationData.CERT3)
      }
    } else {
      setQualificationOptions(qualifications.filter(item => item.id === qualificationData.ECT 
        || item.id === qualificationData.DIPLOMA
        || item.id === qualificationData.ANY
      ))
      if(qualification === qualificationData.CERT3) {
        setQualification(qualificationData.DIPLOMA)
      }
    }
  }

  const [qualification, setQualification] = React.useState('')
  const changeQualification = (event) => {
    setQualification(event.target.value)
    setSelectedEducator(null)
    fetchOrgEducators(startDate, endDate, event.target.value)
    handleRoleField(event.target.value)
  }

  const handleRoleField = (qualificationValue) => {
    switch(qualificationValue) {
      case qualificationData.ANY:
        setCurrentRole('')
        setRoleDisable(true)
        setQualificationOptions(qualifications)
        break
      case qualificationData.CERT3:
          setCurrentRole(rolesData.ASSISTANT)
          setRoleDisable(true)
        break
      case qualificationData.ECT:
          setCurrentRole(rolesData.LEADER)
          setRoleDisable(true)
        break
      default:
        setRoleDisable(false)
    }
  }

  const [description, setDescription] = React.useState('')
  const changeDescription = (event) => {
    setDescription(event.target.value)
  }

  const fetchOrgEducators = useCallback(async (start_date, end_date, qualification) => {
    const qualification_list = !qualification? qualification: `${qualification},${qualification + 1}`
    const params = { start_date, end_date, qualification_list, include_private_educator: true }
    const res = await get(`/manage/organisations/${orgId}/educators`, null, params);
    setAllOrgEducators(res);
    const validEducator = res.find(educator => educator?.id === selectedEducator?.id) || null;
    setSelectedEducator(validEducator);
  }, [orgId, selectedEducator?.id]);

  const [startDate, setStartDate] = React.useState('08:00:00')
  const [endDate, setEndDate] = React.useState('16:00:00')
  const [startTime, setStartTime] = React.useState('')
  const [endTime, setEndTime] = React.useState('')

  function setTimeRange(newValue) {
    setStartTime(convertDateTimeToServerFormat(newValue[0]))
    setEndTime(convertDateTimeToServerFormat(newValue[1]))
  }

  function getShiftDates(startDate, endDate) {
    const dates = []
    let currentDate = new Date(startDate)
    let endD = new Date(endDate)
    while (currentDate <= endD) {
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        dates.push(convertYearMonthDay(currentDate))
      }
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return dates
  }

  function handlePostShift() {
    const validated = validateFormCreate()
    if (!validated) {
      return
    }
    const updateShift = {
      educator_id: (selectedEducator ? selectedEducator.id : null),
      shift_dates: getShiftDates(startDate, endDate),
      shift_start: startTime,
      shift_end: endTime,
      assigment_types: educatorPool,
      bonus: bonus,
      qualification: qualification,
      centre_id: centre,
      active: 'true',
      description: description,
      role: currentRole
    }
    if (updateShift.educator_id === null) {
      delete updateShift.educator_id
    }
    onClickCreate(updateShift)
  }

  const isEndDateTooLate = isEndDateIsTwoMonthsLaterAfterStartDate(new Date(), endDate)
  const isStartDateTooLate= isEndDateIsTwoMonthsLaterAfterStartDate(new Date(), startDate) 
  const isEndDateEarlierThanToday = isDateBeforeCurrentDate(endDate) 
  const isStartDateEarlierThanToday = isDateBeforeCurrentDate(startDate)
  const dateTimeError =
    isEndDateTooLate || isStartDateTooLate
      ? 'Cannot choose a date more than 2 months away.'
      : isEndDateEarlierThanToday || isStartDateEarlierThanToday
      ? 'Cannot choose a date earlier than today.'
      : ''
  const endDateRestriction = get2MonthsLaterDate(new Date() )
  function validateFormCreate() {
    const validateEndTimeSmallerThanStartTime =
      compareTimePickerValues(startTime, endTime) === -1
    const validateUserId = individualAssigned
      ? selectedEducator !== null
      : true
    const validQualificationRole = qualification && (qualification === -1 || !!currentRole)
    return (
      dateTimeError === '' &&
      !isEndDateTooLate &&
      centre !== '' &&
      validQualificationRole &&
      startDate !== '' &&
      endDate !== '' &&
      startTime !== '' &&
      endTime !== '' &&
      validateEndTimeSmallerThanStartTime &&
      validateUserId &&
      educatorPool !== ''
    )
  }

  const clearFormCreate = useCallback((centres, fetchAssignOption) => {
    setSelectedEducator(null)
    setIndividualAssigned(false)
    if (centres.length !== 1) {
      setCentre('')
      setAssignOptions([])
    }
    setQualification('')
    setStartDate('')
    setEndDate('')
    setStartTime('08:00:00')
    setEndTime('16:00:00')
    setBonus('')
    setEducatorPool('')
    setDescription('')
    setQualificationOptions(qualifications)
    setCurrentRole(null)
    setRoleDisable(false)
    if (centres.length === 1) {
      fetchAssignOption(centres[0].id.toString())
      setCentre(centres[0].id.toString())
    }
  }, [qualifications])
  
  const updateDateFilter = (isStartDate, date) => {
    _.debounce(() => {
      if (!isValidDate(date)) {
        return
      }
      
      if (isStartDate) {
        setStartDate(date);
        if (!endDate || compareTwoDates(date, endDate) === 1) {
          setEndDate(date);
        }
      } else {
        setEndDate(date);
        if (compareTwoDates(startDate, date) === 1) {
          setStartDate(date);
        }
      }

      if (isValidDate(date) && (isValidDate(isStartDate ? endDate : startDate) || !(isStartDate ? endDate : startDate))) {
        fetchOrgEducators(
          isStartDate ? date : startDate,
          isStartDate ? endDate : date,
          qualification
        );
      }
    }, 500)();
  }
  useEffect(() => {
    fetchRoleOption()
  }, [fetchRoleOption])
  
  useEffect(() => {
    clearFormCreate(centres, fetchAssignOption)
    setQualificationOptions(qualifications)
  }, [open, centres, fetchAssignOption, fetchRoleOption, setQualificationOptions, qualifications, clearFormCreate])

  return (
    <Dialog sx={styleDialog} open={open} onClose={onCancel}>
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Create a new shift
        </Typography>
        <PaddingBox />
        <M3BodyMedium>Create a new shift for educators to accept</M3BodyMedium>
        <PaddingBox />
        <Row>
          <DropDownButton
            placeholder={'Centre location'}
            currentValue={
              centres.length === 1 && centre === '' ? centres[0].id : centre
            }
            onChange={changeCentre}
            isDisabled={false}
            options={centres.map((centre) => (
              <MenuItem key={centre.id} value={centre.id} dense>
                {centre.name}
              </MenuItem>
            ))}
          />
        </Row>
        <PaddingBox />
        <Row>
          <DropDownButton
            placeholder={'Required qualification'}
            currentValue={qualification}
            onChange={changeQualification}
            isDisabled={false}
            options={qualificationOptions.map((qualification) => (
              <MenuItem key={qualification.id} value={qualification.id} dense>
                {qualification.qualification}
              </MenuItem>
            ))}
          />
          <PaddingBox />
          <DropDownButton
            placeholder={'Role'}
            currentValue={currentRole}
            onChange={changeRole}
            isDisabled={roleDisable}
            options={roleOptions.map((role) => (
              <MenuItem key={role.code} value={role.code} dense>
                {role.name}
              </MenuItem>
            ))}
          />
        </Row>
        <PaddingBox />
        <h4>Shift days</h4>
        <Row>
          <DatePickerTextField
            label={'Start date'}
            onChange={(event) => {
              updateDateFilter(true, event.target.value)
            }}
            value={startDate}
            maxDate={endDateRestriction}
            minDate={today.toISOString().split('T')[0]}
            shouldDisableDate={(date) => {
              const day = date.day()
              return day === 0 || day === 6 || date.isBefore(day(today))
            }}
          />
          <PaddingBox />
          <DatePickerTextField
            id="end-date-picker"
            label={'End date'}
            onChange={(event) => {
              updateDateFilter(false, event.target.value)
            }}
            value={endDate}
            minDate={today.toISOString().split('T')[0]}
            maxDate={endDateRestriction}
            shouldDisableDate={(date) => {
              const day = date.day()
              return day === 0 || day === 6 || date.isBefore(day(today))
            }}
          />
          <PaddingBox />
          <TimeRangePicker
            startTime={startTime}
            endTime={endTime}
            setData={setTimeRange}
          />
        </Row>
        {dateTimeError && (
          <Typography
            variant="caption"
            sx={{
              color: theme.palette.error.main,
              marginTop: '8px',
              marginBottom: '8px'
            }}
          >
            {dateTimeError}
          </Typography>
        )}
        <h4>Shift bonus</h4>
        <Row>
          <DropDownButton
            placeholder={'Shift bonus'}
            currentValue={bonus}
            onChange={changeBonus}
            isDisabled={false}
            options={bonusList.map((option) => (
              <MenuItem key={option} value={option} dense>
                {'$' + option}
              </MenuItem>
            ))}
          />
        </Row>
        <h4>Assign shift to</h4>
        <Row>
          <DropDownButton
            placeholder={'Choose who can see the shift'}
            currentValue={educatorPool}
            onChange={changeEducatorPool}
            isDisabled={assignOptions.length === 0}
            options={assignOptions.map((option) => (
              <MenuItem key={option} value={option} dense>
                {option === 'individual-assigned'
                  ? 'Individual'
                  : option === 'all' ? 'Both'
                  : option === 'org' ? orgName
                  : upperCaseFirstLetter(option) + ' educators'}
              </MenuItem>
            ))}
          />
        </Row>
        {individualAssigned && (
          <div>
            <h4>Choose the educator</h4>
            <Row>
              <Autocomplete
                disablePortal
                id="combo-box-educators"
                fullWidth={true}
                options={allOrgEducators}
                getOptionLabel={(option) => option.name}
                value={selectedEducator}
                onChange={(event, educator) => {
                  if (!educator) {
                    setSelectedEducator(null)
                  } else {
                    setSelectedEducator(educator)
                  }
                }}
                isOptionEqualToValue={(option, value) => {
                  if (!option) {
                    return false
                  }
                  return option !== null && option.id === value.id
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Educators" />
                )}
              />
            </Row>
          </div>
        )}

        <h4>Description</h4>
        <Row>
          <CommonTextField
            isMultiline={true}
            rows={3}
            label={'Description'}
            value={description}
            onChange={changeDescription}
            isDisable={false}
          />
        </Row>
        <PaddingBox />
        <SpacedRow>
          <CloseButton onClick={onCancel} text={'Cancel'} />
          <Button
            variant="contained"
            disabled={!validateFormCreate()}
            sx={{
              textTransform: 'none',
              borderRadius: 28,
              backgroundColor: validateFormCreate()
                ? theme.palette.info.main
                : theme.palette.text.secondary,
              color: 'white'
            }}
            onClick={handlePostShift}
          >
            Post shift
          </Button>
        </SpacedRow>
      </Box>
    </Dialog>
  )
}