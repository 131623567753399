import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { CloseButton } from "./Buttons";
import { modalBoxStyles } from "./ModalStyles";
import { SpacedRow } from "./Scaffolds";

const Video = ({ videoUrl, isOpen, onClose }) => {
  

  return videoUrl ? (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="video-modal-title"
      aria-describedby="video-modal-description"
      sx={{ zIndex: 999 }}
    >
      <Box sx={{...modalBoxStyles , width: 600, height: 600}}>
        <video controls style={{ width: '100%', height: '95%' }}>
          <source src={videoUrl} type="video/mp4" />
        </video>

        <SpacedRow sx={{ position: 'absolute', bottom: 16, right: 16 }}>
          <CloseButton onClick={onClose} text={'Close'} />
        </SpacedRow>
      </Box> 
    </Modal>
  ) : null;
};

export default Video;