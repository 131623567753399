import {
  Box,
  MenuItem,
  Typography,
  FormControl,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  InputLabel,
  Dialog,
} from "@mui/material";
import { PaddingBox, Row, SpacedRow } from "../../../reusable/Scaffolds";
import { CloseButton, DropDownButton, PrimaryButton, RemoveButton } from "../../../reusable/Buttons";
import { useEffect, useState } from "react";
import { formatDate, formatTimestampToddmmyyyy } from "../../../../utils/time";
import { style, styleDialog } from "../../../reusable/Modal";
import { isValidEmail } from "../../../../utils/regex";
import { M3BodyMedium, M3LabelMedium } from "../../../reusable/TextStyles";
import { formatToLocalPhoneNumber, isValidPhoneNumber } from "../../../../utils/helper_functions";
const _ = require("lodash");
function ManageLoginModal({
  open,
  onCancel,
  onClick,
  onPasswordReset,
  centreList,
  fetchListCentre,
  isOwner,
  permissionList,
  user,
  isEditModal = false,
  isMe = false,
  isAdmin = false,
  organisationOptions,
}) {
  const [showCentres, setShowCentres] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [orgId, setOrgId] = useState("");
  const [formData, setFormData] = useState(() => {
    return {
      access: user?.access || "",
      centres: user?.centres || [],
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      email: user?.email || "",
      phone_number: user?.phone_number || "",
      deactivated: user?.deactivated || false,
      organisation_id: user?.organisation_id || "",
    };
  });

  const { access, first_name, last_name, email, phone_number, deactivated, centres, organisation_id } = formData;

  const setFormDataWithCheck = (newFormData) => {
    isFormChanged(newFormData);
    return newFormData;
  };

  const changePermission = (event) => {
    const selectedPermission = event.target.value;
    if (selectedPermission) {
      setShowCentres(!(selectedPermission === "owner"));
    } else {
      setShowCentres(false);
    }
    setFormData((prevData) =>
      setFormDataWithCheck({
        ...prevData,
        access: selectedPermission,
      })
    );
  };

  const changeCentre = (event) => {
    const selectedValues = event.target.value;
    setFormData((prevData) =>
      setFormDataWithCheck({
        ...prevData,
        centres: selectedValues,
      })
    );
  };

  const changeFirstName = (event) => {
    setFormData((prevData) =>
      setFormDataWithCheck({
        ...prevData,
        first_name: event.target.value,
      })
    );
  };

  const changeLastName = (event) => {
    setFormData((prevData) =>
      setFormDataWithCheck({
        ...prevData,
        last_name: event.target.value,
      })
    );
  };

  const changeEmail = (event) => {
    setFormData((prevData) =>
      setFormDataWithCheck({
        ...prevData,
        email: event.target.value,
      })
    );
    setEmailError(
      event.target.value.length >= 1 && !isValidEmail(event.target.value)
    );
  };

  const changePhone = (event) => {
    const phone = event.target.value;
    const isValidPhone = isValidPhoneNumber(phone)

    setFormData((prevData) =>
      setFormDataWithCheck({
        ...prevData,
        phone_number: phone,
      })
    );
    setPhoneError(phone === 0 || !isValidPhone);
  };

  const changeStatus = (event) => {
    setFormData((prevData) =>
      setFormDataWithCheck({
        ...prevData,
        deactivated: !event.target.checked,
      })
    );
  };

  const changeOrganisation = (event) => {
    setOrgId(event.target.value)
    setFormData((prevData) =>
      setFormDataWithCheck({
        ...prevData,
        organisation_id: event.target.value,
      })
    );
    fetchListCentre(event.target.value)
  };

  function handleCreateLogin() {
    const formattedFormData = {
      ...formData,
      phone_number: formatToLocalPhoneNumber(formData.phone_number)
    };
    onClick({
      formData: formattedFormData,
    });
  }

  function isFormChanged(newFormData) {
    const formDataFormatted = {
      access: user?.access || "",
      centres: user?.centres?.map((centre) => centre.id) || [],
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      email: user?.email || "",
      phone_number: user?.phone_number || "",
      deactivated: user?.deactivated || false,
      organisation_id: user?.organisation_id,
    };
    setDisableSave(_.isEqual(formDataFormatted, newFormData));
  }

  function validateFormCreate() {
    return (
      first_name !== "" &&
      last_name !== "" &&
      email !== "" &&
      phone_number !== "" &&
      access !== "" &&
      (!isAdmin || organisation_id !== "") &&
      !emailError &&
      !phoneError &&
      (showCentres ? formatDate.centres !== "" : true)
    );
  }
  const clearStatus = () => {
    setDisableSave(true);
    onCancel();
  };
  function clearFormCreate() {
    setFormData({
      access: "",
      centres: [],
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      deactivated: false,
      organisation_id: ""
    });
    setShowCentres(false);
    setEmailError(false);
    setPhoneError(false);
    setOrgId("");
  }

  useEffect(() => {
    if (user) {
      const org_id = user?.organisation_id || ""
      setOrgId(org_id)
      setFormData({
        access: user?.access || "",
        centres: user?.centres?.map((centre) => centre.id) || [],
        first_name: user?.first_name || "",
        last_name: user?.last_name || "",
        email: user?.email || "",
        phone_number: user?.phone_number || "",
        deactivated: user?.deactivated || false,
        organisation_id: org_id
      });
      if (org_id) {
        fetchListCentre(org_id)
      }
      setShowCentres(!(user.access === "owner"));
    } else {
      clearFormCreate();
    }
  }, [user, open, fetchListCentre]);

  return (
    <Dialog
      sx={styleDialog}
      open={open}
      onClose={clearStatus}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} style={{ width: 400 }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {isOwner
            ? isEditModal
              ? 'Edit login'
              : 'Create a new login'
            : 'View login'}
        </Typography>
        <PaddingBox />
        { isAdmin && 
          <>
            <FormControl fullWidth>
              <InputLabel id="id-assigned-centre-label">
                Organisation
              </InputLabel>
              <Select
                label={'Organisation'}
                id="id-org"
                value={orgId}
                onChange={changeOrganisation}
                disabled={isEditModal}
              >
                {organisationOptions.map((org) => (
                  <MenuItem key={org.id} value={org.id} dense>
                    {org.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <PaddingBox />
          </>
        }
        <FormControl fullWidth>
          <TextField
            label="First name"
            type="text"
            disabled={!isOwner}
            value={first_name}
            onChange={changeFirstName}
          />
        </FormControl>
        <PaddingBox />
        <FormControl fullWidth>
          <TextField
            label="Last name"
            type="text"
            value={last_name}
            disabled={!isOwner}
            onChange={changeLastName}
          />
        </FormControl>
        <PaddingBox />
        <FormControl fullWidth>
          <TextField
            label="Email"
            type="email"
            value={email}
            disabled={!isOwner}
            onChange={changeEmail}
            error={emailError}
          />
        </FormControl>
        <PaddingBox />
        <FormControl fullWidth>
          <TextField
            label="Phone number"
            type="tel"
            value={phone_number}
            disabled={!isOwner}
            onChange={changePhone}
            error={phoneError}
          />
        </FormControl>
        <PaddingBox />
        <Row style={{ width: '100%' }}>
          <DropDownButton
            placeholder={'Role'}
            currentValue={access}
            isDisabled={!isOwner || isMe}
            onChange={changePermission}
            options={permissionList.map((permission) => (
              <MenuItem key={permission} value={permission} dense>
                {permission.charAt(0).toUpperCase() + permission.slice(1)}
              </MenuItem>
            ))}
          />
        </Row>
        <PaddingBox />
        {showCentres && (
          <FormControl fullWidth>
            <InputLabel id="id-assigned-centre-label">
              Assigned centre
            </InputLabel>
            <Select
              label={'Assigned centre'}
              id="id-assigned-centre"
              multiple
              disabled={!isOwner && !isAdmin}
              value={centres}
              onChange={changeCentre}
            >
              {centreList.map((centre) => (
                <MenuItem key={centre.id} value={centre.id}>
                  {centre.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <PaddingBox />
        {isEditModal && (
          <SpacedRow>
            <FormControlLabel
              label="Active"
              control={
                <Checkbox
                  checked={!deactivated}
                  disabled={!isOwner || isMe}
                  onChange={changeStatus}
                />
              }
            />
            <div>
              <M3LabelMedium>Date added</M3LabelMedium>
              <M3BodyMedium sx={{ mt: 1 }}>
                {formatTimestampToddmmyyyy(user?.created_at)}
              </M3BodyMedium>{' '}
            </div>
          </SpacedRow>
        )}
        <PaddingBox />
        <SpacedRow>
          <CloseButton onClick={clearStatus} text={'Cancel'} />
          {isOwner ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'row'
              }}
            >
              {isEditModal & !user?.deactivated ? (
                <RemoveButton
                  text={'Send password reset'}
                  onClick={() => onPasswordReset(user)}
                />
              ) : null}
              <PaddingBox />
              <PrimaryButton
                isDisabled={!validateFormCreate() || disableSave}
                text={isEditModal ? 'Save' : 'Create'}
                onClick={handleCreateLogin}
              ></PrimaryButton>
            </div>
          ) : null}
        </SpacedRow>
      </Box>
    </Dialog>
  )
}

export default ManageLoginModal;
