import React, { useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Grid, Typography, ListItemButton, Box, CircularProgress } from '@mui/material';
import AccountStatus from './account_status';
import { CustomAvatar } from '../../../../Layout';
import {
    stringAvatar,
    extractSuburb,
    extractState
} from '../../../../../utils/helper_functions'
import {
  formatTimestampToMonthYear,
} from '../../../../../utils/time'

const MemberList = ({members, loading, hasMore, page, setPage}) => {
  const observerRef = useRef();
  const navigate = useNavigate();

  // Observer callback to load more items
  const lastItemRef = useCallback((node) => {
    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPage((prevPage) => prevPage + 1); // Increase the page count to fetch next items
      }
    });

    if (node) observerRef.current.observe(node);
  }, [hasMore, setPage]);

  return (
    <Box sx={{ margin: 'auto' }}>
      {loading && page === 1 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="60vh"
        >
          {' '}
          <CircularProgress />{' '}
        </Box>
      ) : (
        <> {
          members.map((member, index) => (
            <ListItemButton
            selected={false}
            onClick={() => {
              navigate(`/applications/${member.id}`);
            }}
            alignItems="flex-start"
            disableRipple
          >
            <Card ref={lastItemRef} key={index} sx={{ mb: 2 }}>
              <CardContent>
                <Grid container spacing={2} alignItems="center">
                  <Grid container item xs={12} sm={4} spacing={1} >
                    <Grid item sm={2}>
                      <CustomAvatar
                            {...stringAvatar(
                              member.first_name + ' ' + member.last_name
                            )}
                            src={
                              member.avatar !== null
                                    ? member.avatar
                                    : null
                            }
                            sx={{marginTop: '15px'}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <Typography variant="h7" sx={{fontWeight: 'bold'}}>{member.prefered_name}</Typography>
                      <Typography color="text.secondary" sx={{fontSize: '13px'}}>{`${extractSuburb(member.address)} ${extractState(member.address)}`}</Typography>
                      <Typography color="text.secondary" sx={{fontSize: '13px'}} noWrap>{member.email}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={8} container spacing={2}>
                    <Grid item xs={12} sm={2}>
                      <AccountStatus label="Account created" status={member.prefered_name ? 'Complete' : 'Verifying'} />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <AccountStatus label="Photo ID" status={member.identity_verified ? 'Verified' : 'Verifying'} />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <AccountStatus label="WWCC/VP" status={member.is_verify_wwcc ? 'Verified' : 'Verifying'} />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <AccountStatus label="Qualifications" status={member.is_verify_qualification ? 'Verified' : 'Verifying'} />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <AccountStatus label="Video introduction" status={member.video_url ? 'Complete' : 'Verifying'} />
                    </Grid>
                    <Grid item xs={12} sm={2} sx={{display: "flex", fontSize: "12px", justifyContent: "center", alignItems: "center" }}>
                    <div title={member.created_at} >
                      Created {formatTimestampToMonthYear(member.created_at)}
                    </div>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            </ListItemButton>
          ))
        }
        {hasMore && (
            <CircularProgress
              sx={{
                display: 'block',
                margin: 'auto'
              }}
            />
        )}
      </>
      )}
    </Box>
  );
};

export default MemberList;
