
const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const setLocalStorageToken = token => localStorage.setItem('token', token);
export const getLocalStorageToken = () => localStorage.getItem('token');
export const removeLocalStorageToken = () => localStorage.removeItem('token');
export const getLocalStorageShiftTab = () => localStorage.getItem('current_shift_tab');
export const setLocalStorageShiftTab = (tab) => localStorage.setItem('current_shift_tab', tab);
export const removeLocalStorageShiftTab = () => localStorage.removeItem('current_shift_tab');
export const getLocalStorageEducatorTab = () => localStorage.getItem('current_educator_tab');
export const setLocalStorageEducatorTab = (tab) => localStorage.setItem('current_educator_tab', tab);
export const removeLocalStorageEducatorTab = () => localStorage.removeItem('current_educator_tab');

const navigateToLogin = () => {
  window.location.href = '/login';
}
const getStandardHeaders = () => {
  const token = getLocalStorageToken();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return {
    'Content-Type': 'application/json',
    'Time-Zone': timezone,
    ...(token ? { Authorization: `${token}` } : {}),
  };
};
function convertObjectToURLParameters(params) {
  return '?' + Object.keys(params).map((key) => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
  }).join('&');
}
export const get = async (endpoint, headers = {}, parameter) => {
  let url = `${BASE_URL}${endpoint}`
  if (parameter){
    const paramsString = convertObjectToURLParameters(parameter);
    url = `${url}${paramsString}`
  }
  const response = await fetch(url, {
    method: 'GET',
    headers: { ...getStandardHeaders(), ...headers },
  });
  return handleResponse(response);
};

export const getFileAsBlob = async (endpoint, headers = {}, parameter) => {
  if(endpoint.endsWith('undefined') || endpoint.endsWith('null'))
  {
    return null;
  }
  let url = `${BASE_URL}${endpoint}`
  if (parameter){
    const paramsString = convertObjectToURLParameters(parameter);
    url = `${url}${paramsString}`
  }
  const response = await fetch(url, {
    method: 'GET',
    headers: { ...getStandardHeaders(), ...headers },
  });
  const blob = await response.blob();
  return URL.createObjectURL(blob);
};

export const post = async (endpoint, body, headers = {}) => {
  const response = await fetch(`${BASE_URL}${endpoint}`, {
    method: 'POST',
    headers: { ...getStandardHeaders(), ...headers },
    body: JSON.stringify(body),
  });
  return handleResponse(response);
};

export const postFile = async (endpoint, file, headers = {}) => {
  const reader = new FileReader();
  const promise = new Promise((resolve, reject) => {
    reader.onloadend = async () => {
      const base64Data = reader.result.split(',')[1];
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...getStandardHeaders(), ...headers },
        body: JSON.stringify({ base64Data, contentType: file.type }),
      });
      resolve(handleResponse(response));
    };
    reader.onerror = reject;
  });
  reader.readAsDataURL(file);
  return promise;
};

export const put = async (endpoint, body, headers = {}) => {
  const response = await fetch(`${BASE_URL}${endpoint}`, {
    method: 'PUT',
    headers: { ...getStandardHeaders(), ...headers },
    body: JSON.stringify(body),
  });
  return handleResponse(response);
};

export const del = async (endpoint, headers = {}) => {
  const response = await fetch(`${BASE_URL}${endpoint}`, {
    method: 'DELETE',
    headers: { ...getStandardHeaders(), ...headers },
  });
  return handleResponse(response);
};

const handleResponse = async (response) => {
  const contentType = response.headers.get('Content-Type');
  const data = await response.json();

  if (!response.ok) {
    if (contentType && contentType.includes('application/json')) {
      if (data.error) {
        throw new Error(data.error);
      }
      if (data.message) {
        throw new Error(data.message);
      }
    }
    const error = await response.text();
    throw new Error(error);
  }

  const currentRoute = window.location.pathname;
  if (response.status === 401 && currentRoute !== '/login') {
    removeLocalStorageToken();
    navigateToLogin();
  }

  return data; // Return the parsed data
};
