import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  Typography,
  Modal,
  Box,
  FormControl,
  MenuItem,
  TextField,
  Input,
  Grid,
} from "@mui/material";
import { modalBoxStyles } from "../reusable/ModalStyles";
import { CustomAvatar } from "../Layout";
import { PaddingBox, SpacedRow } from "../reusable/Scaffolds";
import { CloseButton, DropDownButton, PrimaryButton } from "../reusable/Buttons";
import { qualificationLevelId } from "../../utils/constants";
import { getQualificationDateLabel } from "../../utils/helper_functions";
import { PercentCompleteItem, PreviousQualificationItem, EctLevelItem } from "../reusable/QualificationItem";
import { get } from "../../utils/api";
import { DatePickerTextField } from "../reusable/TextField";

const OrgEducatorEditForm = ({
  open,
  user,
  setOpen,
  handleSaveUser,
  error,
  qualificationLevels,
}) => {
  const { id } = user;

  const initialUserState = useMemo(() => ({
    qualification: user.qualification,
    latest_qualification_date: user.latest_qualification_date,
    previous_qualification: user.previous_qualification,
    milestone_passed: user.milestone_passed,
    is_study_completed: user.is_study_completed,
    ect_level: user.ect_level
  }), [user]);

  const [qualificationLevel, setQualification] = useState(user.qualification);
  const [qualificationDate, setQualificationDate] = useState(user.latest_qualification_date);
  const [previousQualification, setPreviousQualification] = useState(user.previous_qualification);
  const [milestonePassed, setMilestonePassed] = useState(user.milestone_passed);
  const [isStudyCompleted, setIsStudyCompleted] = useState(user.is_study_completed);
  const [ectLevel, setEctLevel] = useState(user.ect_level);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const [ectLevels, setEctLevels] = useState([]);
  const [percentCompletes, setPercentCompletes] = useState([]);

  const qualificationOptions = useMemo(() => {
    const baseOptions = [
      { key: 0, value: qualificationLevelId.NONE, label: 'None' },
      { key: qualificationLevelId.CERT3, value: qualificationLevelId.CERT3, label: 'Cert III' }
    ];
  
    return qualificationLevel === qualificationLevelId.ECT_TOWARRD
      ? [...baseOptions, { key: qualificationLevelId.DIPLOMA, value: qualificationLevelId.DIPLOMA, label: 'Diploma' }]
      : baseOptions;
  }, [qualificationLevel]);

  const highestQualificationWidth = useMemo(() => {
    if (qualificationLevel === qualificationLevelId.UNQUALIFIED) return 12;
    if (qualificationLevel === qualificationLevelId.CERT_TOWARD ||
        qualificationLevel === qualificationLevelId.CERT3 ||
        qualificationLevel === qualificationLevelId.DIPLOMA) return 8;
    return 5;
  }, [qualificationLevel]);

  useEffect(() => {
    setQualification(user.qualification);
    setQualificationDate(user.latest_qualification_date);
    setPreviousQualification(user.previous_qualification);
    setMilestonePassed(user.milestone_passed);
    setEctLevel(user.ect_level);
  }, [user]);

  const validateValue = useCallback(() => {
    return (
      qualificationLevel !== initialUserState.qualification ||
      (qualificationDate !== 'Invalid Date' && qualificationDate !== initialUserState.latest_qualification_date) ||
      previousQualification !== initialUserState.previous_qualification ||
      milestonePassed !== initialUserState.milestone_passed ||
      ectLevel !== initialUserState.ect_level
    );
  }, [qualificationLevel, qualificationDate, previousQualification, milestonePassed, ectLevel, initialUserState]);

  useEffect(() => {
    setIsSaveDisabled(!validateValue());
  }, [qualificationLevel, qualificationDate, previousQualification, isStudyCompleted, milestonePassed, ectLevel, validateValue]);

  useEffect(() => {
    fetchPercentCompletes();
    fetchEctLevels();
  }, []);

  const handleClose = () => {
    setQualification(initialUserState.qualification);
    setQualificationDate(initialUserState.latest_qualification_date);
    setPreviousQualification(initialUserState.previous_qualification);
    setMilestonePassed(initialUserState.milestone_passed);
    setEctLevel(initialUserState.ect_level);
    setOpen(false);
  };

  const changeQualification = (event) => {
    setQualification(event.target.value)
    setQualificationDate(null)
    setMilestonePassed('')
    setPreviousQualification(qualificationLevelId.NONE)
    
    if(event.target.value !== qualificationLevelId.ECT) {
      setEctLevel(null)
    }
  };

  const changeQualificationDate = (event) => {
    setQualificationDate(event.target.value)
  };

  const changePreviousQualification = (event) => {
    setPreviousQualification(event.target.value);
  };

  const changePercentComplete = (event) => {
    const selectedValue = event.target.value === '' ? null : event.target.value;
    setMilestonePassed(selectedValue);
    setIsStudyCompleted(selectedValue);
  };

  const changeEctLevel = (event) => {
    setEctLevel(event.target.value);
  };

  const fetchEctLevels = async () => {
    try {
      const ectLevels = await get('/manage/ect-levels', null)
      setEctLevels(ectLevels.ectLevels)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchPercentCompletes = async () => {
    try {
      const data = await get('/manage/percent-completes', null)
      setPercentCompletes(data.percentCompletes)
    } catch (error) {
      console.error(error)
    }
  }

  const handleUpdateProfile = async (event) => {
    event.preventDefault()

    const body = {
      qualification: (qualificationLevel < 0 || qualificationLevel === '') ? null: qualificationLevel,
      latest_qualification_date: qualificationDate,
      previous_qualification: (previousQualification < 0 || previousQualification === '') ? null: previousQualification,
      is_study_completed: isStudyCompleted,
      ect_level: ectLevel,
      milestone_passed: milestonePassed === ''? null : milestonePassed
    };

    handleSaveUser(id, body);
  };

  const renderQualificationFields = () => {
    switch (qualificationLevel) {
      case qualificationLevelId.ECT:
        return (
          <>
          <EctLevelItem 
            xs={3}
            currentValue={ectLevel}
            onChange={changeEctLevel}
            options={
              ectLevels.map((option) => (
                <MenuItem
                  key={option.id}
                  value={option.value}
                  dense
                >
                  {option.label}
                </MenuItem>
              ))
            }
          />
          <Grid item xs={4}>
            <DatePickerTextField
              label={getQualificationDateLabel(qualificationLevel)}
              value={qualificationDate}
              onChange={changeQualificationDate}
            />
          </Grid>
          </>
        );
      case qualificationLevelId.DIPLOMA_TOWARD:
      case qualificationLevelId.ECT_TOWARRD:
        return (
          <>
            <PercentCompleteItem 
              xs={3}
              milestone_passed={milestonePassed === null ? '' : milestonePassed}
              onChange={changePercentComplete}
              percentCompletes={percentCompletes}
            />
            <PreviousQualificationItem 
              xs={12}
              currentValue={previousQualification || qualificationLevelId.NONE}
              onChange={changePreviousQualification}
              options={qualificationOptions.map(option => (
                <MenuItem key={option.key} value={option.value} dense>
                  {option.label}
                </MenuItem>
              ))}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyles}>
        <CustomAvatar
          src={user.avatar_url ?? ""}
          sx={{ height: 100, width: 100 }}
        />
        <PaddingBox />
        <Typography variant="h5">
          {user.first_name} {user.prefered_name && `(${user.prefered_name})`} {user.last_name}
        </Typography>
        <FormControl>
            <Input
              id="availability"
              value={user.available ? "Available to work" : "Unavailable"}
              variant="outlined"
              disabled={true}
            />
        </FormControl>
        <form>
          {error && (
            <Alert severity="error" style={{ margin: "10px 0" }}>
              {error}
            </Alert>
          )}
          <PaddingBox></PaddingBox>
          <FormControl fullWidth>
            <TextField
              label="Email"
              type="email"
              value={user.email}
              disabled={true}
            />
          </FormControl>
          <PaddingBox></PaddingBox>
          <FormControl fullWidth>
            <TextField
              label="Phone Number"
              type="tel"
              value={user.phone_number}
              disabled={true}
            />
          </FormControl>
          <PaddingBox></PaddingBox>
          <FormControl fullWidth>
            <TextField
              label="Address"
              value={user.address}
              disabled={true}
            ></TextField>
          </FormControl>
          <PaddingBox></PaddingBox>
          <Grid container spacing={2}>
            <Grid item xs={highestQualificationWidth}>
              <DropDownButton
                placeholder={'Highest Qualification'}
                currentValue={qualificationLevel ?? ''}
                onChange={changeQualification}
                options={qualificationLevels.map((qualification) => (
                  <MenuItem key={qualification.id} value={qualification.id} dense>
                    {qualification.qualification}
                  </MenuItem>
                ))}
              />
            </Grid>
            {qualificationLevel !== qualificationLevelId.UNQUALIFIED && qualificationLevel !== qualificationLevelId.ECT && (
              <Grid item xs={4}>
                <DatePickerTextField
                  label={getQualificationDateLabel(qualificationLevel)}
                  value={qualificationDate}
                  onChange={changeQualificationDate}
                />
              </Grid>
            )}
            {renderQualificationFields()}
          </Grid>
          <br />
          <br />
          <SpacedRow>
          <CloseButton onClick={handleClose} text={'Cancel'} />
          <PrimaryButton
            text="Save"
            isDisabled={isSaveDisabled}
            onClick={(e) => handleUpdateProfile(e)}
          />
          </SpacedRow>
        </form>
      </Box>
    </Modal>
  );
};

export default OrgEducatorEditForm;
