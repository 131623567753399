import styled from '@emotion/styled'
import { Tab, Tabs, Skeleton } from '@mui/material'
import { M3BodyMedium, M3LabelMedium, M3BodyLarge, M3TitleSmall, M3BodySmall } from '../../../reusable/TextStyles'
import { PaddingBox } from '../../../reusable/Scaffolds'
import DeleteIcon from '@mui/icons-material/Delete';
import {
  formatTimestampToMonthYear,
  formatTimestampToddmmyyyy
} from '../../../../utils/time'
import { getQualificationName } from '../../../../utils/education'
import { stringAvatar } from '../../../../utils/helper_functions'
import { CustomAvatar } from '../../../Layout';
const CustomTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: ${(props) => props.theme.palette.info.main};
  }
`

const CustomTab = styled(Tab)`
  text-transform: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  min-width: 30%;
  letter-spacing: 0.1px;
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.text.secondary};
  &.Mui-selected {
    color: ${(props) => props.theme.palette.info.main};
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TightRow = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 12;
  padding: 0;
`
const EducatorList = styled.div`
  margin-top: 20px;
`

const UserRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`

const DateAdded = styled.div`
  font-size: 10px;
`

const EducatorInfo = styled.div`
  flex-grow: 1;
`

const UserName = styled.p`
  margin: 0;
`

const UserBlacklisted = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: end;
`

const UserQualification = styled(M3BodyMedium)``

const UserStatus = styled(M3LabelMedium)`
  margin-left: auto;
  color: ${(props) => (props.available ? 'green' : 'darkgrey')};
`

const EducatorRow = ({  user, callback, style, qualifications, isPrivate }) => {
  return (
    <UserRow style={{ ...style }}>
      {user ? (
        <CustomAvatar
          src={user.avatar_url}
          {...stringAvatar(user.first_name + ' ' + user.last_name)}
        />
      ) : (
        <Skeleton variant="circular" width={40} height={40} />
      )}
      <PaddingBox />
      <EducatorInfo onClick={() => callback(user)}>
        {user ? (
          <UserStatus available={user.available}>
            {user.available ? 'Available to work' : 'Unavailable'}
          </UserStatus>
        ) : (
          <Skeleton width={100} />
        )}

        {user ? (
          <M3BodyLarge>
            {user.first_name} {user.prefered_name && `(${user.prefered_name}) `}
            {user.last_name}
          </M3BodyLarge>
        ) : (
          <Skeleton width={200} />
        )}
        {user ? (
          <UserQualification>
            {user.qualification
              ? getQualificationName(user?.qualification, qualifications)
              : 'Unqualified'}
          </UserQualification>
        ) : (
          <Skeleton width={100} />
        )}
      </EducatorInfo>
      {isPrivate ? (
        <div style={{width: '40%'}} >
          {user.organisations && user.organisations.length > 0
            ? user.organisations.map((org) => {
                return (
                  <TightRow style={{ marginBottom: 4 }}>
                    <CustomAvatar
                      style={{ width: 24, height: 24, marginRight: 4 }}
                      src={org.logo_url}
                      {...stringAvatar(org.name)}
                    />
                    <M3BodySmall>{org.name}</M3BodySmall>
                  </TightRow>
                ) 
              })
            : null}
        </div>
      ) : null}
      {user ? (
        <DateAdded title={user.created_at}>
          Added {formatTimestampToMonthYear(user.created_at)}
        </DateAdded>
      ) : (
        <Skeleton width={100} />
      )}
    </UserRow>
  )
}

const PendingEducatorRow = ({ key, user, style, onCancelClick }) => {
  const displayName = user
    ? user.first_name || user.last_name
      ? `${user.first_name} ${user.last_name} <${user.email}>`
      : user.email
    : null
  return (
    <UserRow key={key} style={style}>
      {user ? (
        <CustomAvatar
          src={user.avatar_url || null}
          {...stringAvatar(user.first_name + ' ' + user.last_name)}
        />
      ) : (
        <Skeleton variant="circular" width={40} height={40} />
      )}
      <PaddingBox />
      <div>
        {' '}
        <EducatorInfo>
          {user ? (
            <M3BodyLarge>{displayName}</M3BodyLarge>
          ) : (
            <Skeleton variant="text" width={200} />
          )}
        </EducatorInfo>{' '}
        {user ? (
          <DateAdded title={user.created_at} sx={{ float: 'left' }}>
            Invited on {formatTimestampToddmmyyyy(user.created_at)}
          </DateAdded>
        ) : (
          <Skeleton width={100} />
        )}
      </div>
        
      <PaddingBox />
      {user ? (
        <DeleteIcon onClick={onCancelClick} sx={{ color: 'red' , marginLeft: 'auto' }}></DeleteIcon>
      ) : null}
    </UserRow>
  )
}

const BlacklistedRow = ({ key, user, style, qualifications, callback }) => {
  return (
    <UserRow style={{ ...style }} key={key} onClick={callback}>
      {user ? (
        <CustomAvatar
          src={user.avatar_url}
          {...stringAvatar(user.first_name + ' ' + user.last_name)}
        />
      ) : (
        <Skeleton variant="circular" width={40} height={40} />
      )}
      <PaddingBox />
      <EducatorInfo>
        {user ? (
          <M3BodyLarge>
            {user.first_name} {user.prefered_name && `(${user.prefered_name}) `}{user.last_name}
          </M3BodyLarge>
        ) : (
          <Skeleton width={200} />
        )}
        {user ? (
          <UserQualification>
            {user.qualification
              ? getQualificationName(user?.qualification, qualifications)
              : 'Unqualified'}
          </UserQualification>
        ) : (
          <Skeleton width={100} />
        )}
        {user ? (
          <M3TitleSmall>Reason: <M3BodyMedium>
             {user.reason? user.reason : 'n/a'}
            </M3BodyMedium>
          </M3TitleSmall>
        ) : (
          <Skeleton width={100} />
        )}
      </EducatorInfo>
      {user ? (
        <UserBlacklisted>
          <DateAdded title={user.created_at}>
            Blocked on {formatTimestampToMonthYear(user.created_at)}
          </DateAdded>
          <DateAdded title={user.created_at}>
            {"Blocked by " + user.blocker_first_name ?? ""}{" "}
            {user.blocker_last_name ?? ""}
          </DateAdded>
        </UserBlacklisted>
        
      ) : (
        <Skeleton width={100} />
      )}
      <PaddingBox />
    </UserRow>
  )
}

export {
  CustomTabs,
  EducatorRow,
  PendingEducatorRow,
  BlacklistedRow,
  CustomTab,
  Row,
  EducatorList,
  UserRow,
  DateAdded,
  EducatorInfo,
  UserName,
  UserQualification,
  UserStatus
}
