import { Box, MenuItem, Modal, Grid } from "@mui/material"
import { modalBoxStyles } from "../../../reusable/ModalStyles"
import { M3BodyMedium, M3BodySmall, M3TitleLarge, M3TitleMedium } from "../../../reusable/TextStyles"
import { PaddingBox, Row, SpacedRow } from "../../../reusable/Scaffolds"
import { CloseButton, DropDownButton, PrimaryButton, RemoveButton } from "../../../reusable/Buttons"
import { CommonTextField, DatePickerTextField } from "../../../reusable/TextField"
import { useEffect, useState, useMemo, useCallback } from "react"
import { get, post } from "../../../../utils/api"
import { ErrorDialogModal } from "../../../reusable/Modal"
import { hasNoSpaces, hasNoSpacesAndIsNumber, getQualificationDateLabel } from "../../../../utils/helper_functions"
import { isValidHttpsUrl } from "../../../../utils/regex"
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CheckIcon from '@mui/icons-material/Check';
import { qualificationLevelId } from "../../../../utils/constants"
import { PercentCompleteItem } from "../../../reusable/QualificationItem"
const _ = require('lodash')

const textFields = [
  {
    id : 'payroll_id',
    label: 'CloudPayroll ID',
    title: 'ID for associated CloudPayroll record',
  },  
  {
    id : 'qualification',
    label: 'Qualification',
    title: 'Highest Qualification ',
  },  
]

const copiableFields = [
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'phone_number',
    label: 'Phone number',
  },
  {
    id: 'address',
    label: 'Address',
  }
]

const isQualificationInLevels = (levels, qualificationId) => {
  return levels.includes(qualificationId);
};  

export const ApplicationReviewModal = ({ people, open, closeModal, setAlert }) => {
  const [editPeole, setEditPeople] = useState(people)  
  const [qualificationList, setQualificationList] = useState([])
  const [ectLevels, setEctLevels] = useState([])
  const [percentCompletes, setPercentCompletes] = useState([])
  const [disableSave, setDisableSave] = useState(true)
  const [disableApproval, setDisableApproval] = useState(true)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [copiedField , setCopiedField] = useState({
    id: '',
    value: '',
  })

  const [errorFields, setErrorFields] = useState({
    document_url: false,
    payroll_id: false,
  })
  const [currentError, setCurrentError] = useState(null)

  const isUnqualified = useMemo(() => {
    return editPeole?.qualification.id === qualificationLevelId.UNQUALIFIED;
  }, [editPeole?.qualification.id]);

  const isEctQualification = useMemo(() => {
    return editPeole?.qualification.id === qualificationLevelId.ECT;
  }, [editPeole?.qualification.id]);

  const isDiplomaOrEctToward = useMemo(() => {
    return isQualificationInLevels(
      [qualificationLevelId.DIPLOMA_TOWARD, qualificationLevelId.ECT_TOWARRD],
      editPeole?.qualification?.id
    );
  }, [editPeole?.qualification.id]);

  const isCertificationOrDiploma = useMemo(() => {
    return isQualificationInLevels(
      [qualificationLevelId.CERT_TOWARD, qualificationLevelId.CERT3, qualificationLevelId.DIPLOMA],
      editPeole?.qualification?.id
    );
  }, [editPeole?.qualification.id]);

  const highestQualificationWidth = useMemo(() => {
    if (isUnqualified) return 12;
    if (isCertificationOrDiploma) return 8;
    return 5;
  }, [isUnqualified, isCertificationOrDiploma]);

  const onClose = () => {
    setCopiedField({
      id: '',
      value: '',
    })
    closeModal()
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'payroll_id') {
      cloudPayrollIdCheck(value)
    }
    setEditPeople(() =>
      validateForm({
        ...editPeole,
        [name]:
          value === '' || value === 0
            ? null
            : name === 'quickcare_wage'
            ? parseFloat(parseFloat(value).toFixed(2))
            : value
      })
    )
  };
  const cloudPayrollIdCheck = async (value) => {
    get('/admin/educators/cloud_payroll_check', null, { payroll_id: value })
      .then((res) => {
        if (res.exists) {
          setCurrentError('CloudPayroll ID already exists')
          setErrorFields((prev) => ({
            ...prev,
            payroll_id: true,
          }))
        } else {
          setCurrentError(null)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleSaveUser = async () => {
    const previousQualification = editPeole?.previous_qualification?.id

    const updatedPeople = {
      ...editPeole,
      qualification: editPeole?.qualification?.id ?? null,
      previous_qualification: (previousQualification < 0 || previousQualification === '') ? null: previousQualification,
      ect_level: editPeole?.ect_level,
      milestone_passed: editPeole?.milestone_passed === ''? null: editPeole?.milestone_passed,
      latest_qualification_date: editPeole?.latest_qualification_date === 'Invalid Date' ? null : editPeole?.latest_qualification_date || null,
    };
  
    post(`/admin/users/educators/${people.id}/update`, updatedPeople)
      .then((res) => {
        setDisableSave(true);
        onClose();
        setAlert('User updated successfully', 'success');
      })
      .catch((error) => {
        setAlert(error.message, 'error');
        setCurrentError(error.message);
      });
  };
  const copyItem = (field) => {
    navigator.clipboard.writeText(field.value)
    setCopiedField(field)
  }
  const handleRejectUser = async () => {
    try {
      await post(`/admin/applications/${people.id}/reject`)
      setDisableSave(true)
      setAlert('Application rejected for' + people.first_name + people.last_name, 'success') 
      onClose()
    } catch (error) {
      setAlert(error.message, 'error')
      setCurrentError(error.message)
    }
  }
  const handleApproveUser = async () => {
    try {
      await post(`/admin/applications/${people.id}/approve`, {
        ...editPeole,
        qualification: editPeole?.qualification?.id,
        previous_qualification: editPeole?.previous_qualification?.id,
        ect_level: editPeole?.ect_level,
        milestone_passed: editPeole?.milestone_passed === ''? null: editPeole?.milestone_passed,
        latest_qualification_date: editPeole?.latest_qualification_date
      })
      setDisableSave(true)
      setAlert('Application approved for' + people.first_name + people.last_name, 'success')
      onClose()
    } catch (error) {
      setAlert(error.message, 'error')
      setCurrentError(error.message)
    }
  }
  const fetchQualifications = async () => {
    try {
      const orgData = await get('/manage/qualifications', null, {
        is_profile: true
      })
      const qualificationMapped = orgData.qualifications.filter(item => item.id !== qualificationLevelId.ANY).map(
        (qualification) => {
          qualification.value = qualification.id
          qualification.name = qualification.qualification
          return qualification
        }
      )
      setQualificationList(qualificationMapped)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchEctLevels = async () => {
    try {
      const ectLevels = await get('/manage/ect-levels', null)
      setEctLevels(ectLevels.ectLevels)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchPercentCompletes = async () => {
    try {
      const data = await get('/manage/percent-completes', null)
      setPercentCompletes(data.percentCompletes)
    } catch (error) {
      console.error(error)
    }
  }

  const validateForm = (updateState) => {
    const isDateValid = 
    updateState.qualification.id === qualificationLevelId.UNQUALIFIED || 
    (
      updateState.latest_qualification_date !== 'Invalid Date' && 
      updateState.latest_qualification_date !== null
    );
    setDisableSave(
      _.isEqual(people, updateState)|| !isDateValid
    );
    approvalDisableCheck(updateState)
    return updateState
  }
  useEffect(() => {
    setEditPeople(people) 
    setDisableSave(true)
    if(people){
      approvalDisableCheck(people)
    }
  }, [people])

  useEffect(() => {
    fetchQualifications()
    fetchPercentCompletes()
    fetchEctLevels()
  }, [])

  const approvalDisableCheck = (updateState) => {
    try {
      parseInt(updateState.payroll_id)
    } catch (error) {
      setDisableApproval(true)
    }
    setErrorFields((prev) => ({
      ...prev,
      payroll_id: !hasNoSpacesAndIsNumber(updateState.payroll_id),
    }))
    setErrorFields((prev) => ({
      ...prev,
      document_url: !isValidHttpsUrl(updateState.document_url),
    }))
  
    if (
      updateState.qualification &&
      updateState.payroll_id &&
      hasNoSpaces(updateState.payroll_id)
    ) {
      setDisableApproval(false)
    } else {
      setDisableApproval(true)
    }
  }
  const handleQualificationChange = (event) => {
    const selectedQualification = qualificationList
    .filter((e) => e.id === event.target.value)[0]
      setEditPeople(() =>
        validateForm({
          ...editPeole,
          qualification: selectedQualification,
          latest_qualification_date: null,
          milestone_passed: '',
          previous_qualification: qualificationLevelId.NONE,
          ect_level: event.target.value !== qualificationLevelId.ECT? null: editPeole.ect_level
        })
      )
  }
  const handlePreviousQualificationChange = (event) => {
    const selectedQualification = qualificationList
    .filter((e) => e.id === event.target.value)[0];
      setEditPeople(() =>
        validateForm({
          ...editPeole,
          previous_qualification: selectedQualification
      })
    )
  }
  const handleQualificationDateChange = (event) => {
    setEditPeople(
      validateForm({
        ...editPeole,
        latest_qualification_date: event.target.value === 'Invalid Date'? null: event.target.value
      })
    )
  }
  const changePercentComplete = (event) => {
    const selectedValue = event.target.value === '' ? null : event.target.value
    setEditPeople(
      validateForm({
        ...editPeole,
        milestone_passed: selectedValue,
        is_study_completed: selectedValue
      })
    )
  };
  const getPreviousQualificationOptions = () => {
    const highestQualificationId = editPeole?.qualification?.id;
    if (highestQualificationId === qualificationLevelId.DIPLOMA_TOWARD) {
      return [
        { id: 0, value: qualificationLevelId.NONE, qualification: 'None' },
        { id: 1, value: qualificationLevelId.CERT3, qualification: 'Cert III' },
      ]
    } else if (highestQualificationId === qualificationLevelId.ECT_TOWARRD) {
      return [
        { id: 0, value: qualificationLevelId.NONE, qualification: 'None' },
        { id: 1, value: qualificationLevelId.CERT3, qualification: 'Cert III' },
        { id: 2, value: qualificationLevelId.DIPLOMA, qualification: 'Diploma' },
      ]
    }
    return []
  }
  
  const shouldDisableApproval = useCallback((editPeole, isCertificationOrDiploma, isEctQualification, isDiplomaOrEctToward) => {
    const latestQualificationDate = editPeole?.latest_qualification_date
    const ectLevel = editPeole?.ect_level
    const percentComplete = editPeole?.milestone_passed
    const previousQualification = editPeole?.previous_qualification
    const verified = editPeole?.verified
  
    if (isCertificationOrDiploma && !latestQualificationDate) {
      return true
    } else if (isEctQualification && (!latestQualificationDate || !ectLevel)) {
      return true
    } else if (isDiplomaOrEctToward && (percentComplete === null || !previousQualification || !latestQualificationDate)) {
      return true
    } else if (isUnqualified && verified) {
      return true
    }

    return false;
  }, [isUnqualified]);
  useEffect(() => {
    setDisableApproval(shouldDisableApproval(editPeole, isCertificationOrDiploma, isEctQualification, isDiplomaOrEctToward));
  }, [isCertificationOrDiploma, isEctQualification, isDiplomaOrEctToward, editPeole, shouldDisableApproval]);
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {people ? (
        <Box
          sx={modalBoxStyles}
          style={{
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
        >
          {' '}
          <ErrorDialogModal
            open={confirmDialog}
            onClose={() => {
              setConfirmDialog(false)
            }}
            title={'Are you sure you want to reject this application?'}
            onClickCTA={() => {
              handleRejectUser()
            }}
            content={
              'Please ensure you have contact the applicant and informed them.\nYou will not be able to access their data after application is rejected'
            }
            ctaTitle={'Yes'}
          ></ErrorDialogModal>
          <SpacedRow>
            <CloseButton onClick={onClose} text={'Cancel'}></CloseButton>
            <M3TitleLarge>
              {people.first_name + ' ' + people.last_name}
            </M3TitleLarge>
            <PrimaryButton
              text={'Save'}
              onClick={handleSaveUser}
              isDisabled={disableSave}
            ></PrimaryButton>
          </SpacedRow>
          <PaddingBox></PaddingBox>
          {currentError ?<M3BodyMedium color="red">{currentError}</M3BodyMedium>: null}
          <PaddingBox></PaddingBox>
          {copiableFields.map((field, index) => {
            if (people[field.id]) {
              return (
                <Box key={index}>
                  <M3TitleMedium>{field.label}</M3TitleMedium>
                  <Box sx={{ height: 1 }} />
                  <Row>
                    <div>{people[field.id].toString()}</div>
                    <PaddingBox />
                    {copiedField.id !== field.id ? (
                      <ContentPasteIcon
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          copyItem({ id: field.id, value: people[field.id] })
                        }}
                      ></ContentPasteIcon>
                    ) : (
                      <Row>
                        <CheckIcon color="success"></CheckIcon>
                        <M3BodySmall sx={{ paddingLeft: '4px', color: 'gray' }}>
                          Copied
                        </M3BodySmall>
                      </Row>
                    )}
                  </Row>
                  <PaddingBox />
                </Box>
              )
            } else {
              return null
            }
          })}
          {people &&
            textFields.map((field, index) => {
              if (field.id === 'qualification') {
                return (
                  <Box key={index}>
                    <M3TitleMedium>{field.title}</M3TitleMedium>
                    <Box sx={{ height: 12 }} />
                    <Grid container spacing={2}>
                      <Grid item xs={highestQualificationWidth}>
                        <DropDownButton
                          key={index}
                          placeholder={'Highest Qualification'}
                          currentValue={editPeole?.qualification.id ?? 0}
                          onChange={handleQualificationChange}
                          options={qualificationList.map((qualification) => (
                            <MenuItem
                              key={qualification.id}
                              value={qualification.id}
                              dense
                            >
                              {qualification.qualification}
                            </MenuItem>
                          ))}
                        />
                      </Grid>
                      {isEctQualification && (
                        <Grid item xs={3}>
                          <DropDownButton
                            key={index}
                            placeholder={'ECT level'}
                            currentValue={editPeole?.ect_level ?? ''}
                            onChange={(event) =>
                              setEditPeople(
                                  validateForm({
                                  ...editPeole,
                                  ect_level: event.target.value,
                                })
                              )
                            }
                            options={
                              ectLevels.map((option) => (
                                <MenuItem
                                  key={option.id}
                                  value={option.value}
                                  dense
                                >
                                  {option.label}
                                </MenuItem>
                              ))
                            }
                            />
                        </Grid>
                      )}
                      {!isUnqualified && (
                        <Grid item xs={4}>
                          <DatePickerTextField
                            label={getQualificationDateLabel(editPeole?.qualification.id)}
                            onChange={handleQualificationDateChange}
                            value={editPeole?.latest_qualification_date}
                          ></DatePickerTextField>
                        </Grid>
                      )}
                      {isDiplomaOrEctToward && (
                        <PercentCompleteItem 
                          xs={3}
                          milestone_passed={editPeole?.milestone_passed === null ? '' : editPeole?.milestone_passed}
                          onChange={changePercentComplete}
                          percentCompletes={percentCompletes}
                        />    
                      )}
                      <Box sx={{ height: 14 }} />
                      {isDiplomaOrEctToward && (
                        <Grid item xs={12}>
                          <DropDownButton
                            key={index}
                            placeholder={'Previous qualification'}
                            currentValue={editPeole?.previous_qualification?.id || qualificationLevelId.NONE}
                            onChange={handlePreviousQualificationChange}
                            options={getPreviousQualificationOptions().map((qualification) => (
                              <MenuItem key={qualification.id} value={qualification.value} dense>
                                {qualification?.qualification}
                              </MenuItem>
                            ))}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                )
              }
              return (
                <Box key={index}>
                  <M3TitleMedium>{field.title}</M3TitleMedium>
                  <Box sx={{ height: 8 }} />
                  <CommonTextField
                    name={field.id}
                    onChange={handleInputChange}
                    error={errorFields[field.id]} 
                    startAdornment={field.id === 'quickcare_wage' ? '$' : null}
                    type={
                      field.id === 'document_url'
                        ? 'url'
                        : field.id === 'quickcare_wage'
                        ? 'number'
                        : 'text'
                    }
                    value={editPeole?.[field.id] ?? ''}
                  ></CommonTextField>
                  <PaddingBox />
                </Box>
              )
            })}
          <PaddingBox></PaddingBox>
          <SpacedRow>
            <RemoveButton
              sx={{ flex: 1 }}
              text={'Reject application'}
              onClick={() => setConfirmDialog(true)}
            ></RemoveButton>
            <PrimaryButton
              text={'Approve application'}
              onClick={handleApproveUser}
              isDisabled={disableApproval}
            ></PrimaryButton>
          </SpacedRow>
        </Box>
      ) : (
        <Box></Box>
      )}
    </Modal>
  )
}