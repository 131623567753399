import { Container, List } from '@mui/material'
import {
  LoginItemList,
  SkeletonLoginItemList
} from './components/active_user_item'
import { useCallback, useContext, useEffect, useState } from 'react'
import { get } from '../../../../utils/api'
import { UserContext } from '../../../../utils/context'

const ActiveTab = ({ org_id, onItemClick, update }) => {
  const currentUser = useContext(UserContext)
  const [userList, setUserList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const fetchActiveListStaff = useCallback(async () => {
    setIsLoading(true)
    try {
      const res = await get(
        `/manage/organisations/${org_id}/staff?status=active`
      )
      setUserList(res)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }, [org_id])

  useEffect(() => {
    fetchActiveListStaff()
  }, [fetchActiveListStaff, update])

  return (
    <List>
      {isLoading ? (
        <SkeletonLoginItemList key={0} />
      ) : userList.length === 0 ? (
        <Container>
          <p>No logins found</p>
        </Container>
      ) : (
        userList &&
        userList.map((user) => (
          <LoginItemList
            key={user.id}
            onClick={() => onItemClick(user)}
            params={{
              user,
              isMe: currentUser.id === user.id
            }}
          />
        ))
      )}
    </List>
  )
}

export default ActiveTab
