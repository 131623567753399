import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import { getFileAsBlob } from '../../utils/api'
import { Worker, Viewer } from '@react-pdf-viewer/core'
import DownloadIcon from '@mui/icons-material/Download';

import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const FilePreview = ({ fileUrl, title }) => {
  const [fileSrc, setFileSrc] = useState('');

  const previewStyle = {
    borderRadius: 8,
    width: '200px',
    height: '150px',
    textAlign: 'left',
    border: '1px solid #ccc'
  };

  const fetchFile = useCallback(async (url) => {
    try {
      const fileSrc = await getFileAsBlob(fileUrl);
      setFileSrc(fileSrc);
    } catch (error) {
      console.error('Error fetching file:', error);
    }
  }, [fileUrl]);

  const handleDownload = () => {
    const link = window.document.createElement("a");
    link.href = fileSrc;
    link.download = fileUrl ? fileUrl.split("/").pop() : "downloaded-file";
    window.document.body.appendChild(link);
    link.click();
    window.document.body.removeChild(link);
  };

  useEffect(() => {
    if (!fileUrl.endsWith('undefined') && !fileUrl.endsWith('null')) {
      fetchFile(fileUrl);
    }
  }, [fileUrl, fetchFile]);

  return (fileSrc && !fileUrl.endsWith('undefined') && !fileUrl.endsWith('null')) ? (
    <Grid item xs={12}>
      <Grid container xs={12}>
        <Grid xs={4}>
          <Typography variant="subtitle1">{title}</Typography>
        </Grid>
        <Grid xs={4}>
          <IconButton color="info" onClick={handleDownload} sx={{ bottom: 8, right: 8, cursor: 'pointer' }} >
          <DownloadIcon />
        </IconButton>
        </Grid>
      </Grid>
      {fileUrl?.endsWith('pdf') ? (
        <Worker workerUrl="/pdf.worker.min.js">
          <Viewer fileUrl={fileSrc} initialPage={0} />
        </Worker>
      ) : (
        <img src={fileSrc} alt={title} style={previewStyle} />
      )}
    </Grid>
  ) : null;
};

export default FilePreview;