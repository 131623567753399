// src/components/pages/VerifyEmployerPage.js
import React from 'react';
import VerifyEmployerForm from '../../forms/VerifyEmployerForm';

const VerifyEmployerPage = () => {
  return (
    <div>
      <h1>Verify Employer</h1>
      <VerifyEmployerForm />
    </div>
  );
};

export default VerifyEmployerPage;