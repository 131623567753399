import {
    CardContent, Chip, Box
} from '@mui/material'
import React from 'react'
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import PersonIcon from '@mui/icons-material/Person';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { M3TitleMedium, M3LabelMedium } from '../../../reusable/TextStyles';

import {
    stringAvatar,
    isIndividualAssigned
} from '../../../../utils/helper_functions'
import theme from '../../../../utils/theme';
import { CustomAvatar } from '../../../Layout';

function AssignmentItem({ params }) {
    const { shift } = params
    const assignmentIconText = {
        'all': 'All',
        'centre': 'Centre',
        'individual-assigned': 'Individual',
        'individual-rejected': 'Individual',
        'org': 'Organisation',
        'quickcare': 'Quickcare'
    }

    const assignmentSessionText = {
        'all': 'All Educators',
        'centre': 'Centre Group',
        'individual-assigned': 'Individual',
        'individual-rejected': 'Individual',
        'org': 'Organisation Educators',
        'quickcare': 'Quickcare Educators'
    }

    const iconBackground = { color: theme.palette.primary.onPrimary }

    const renderAssignmentIcon = (assignmentType) => {
        switch (assignmentType) {
            case 'individual-assigned':
            case 'individual-rejected':
                return <PersonIcon sx={iconBackground} />
            case 'centre':
                return <OtherHousesOutlinedIcon sx={iconBackground} />
            case 'org':
                return <ApartmentIcon sx={iconBackground} />
            case 'quickcare':
                return <CustomAvatar src={'/splash.png'} sx={{ width: '34px', height: '34px' }} />
            default:
                return <PublicOutlinedIcon sx={iconBackground} />
        }
    }

    const isIndividual = isIndividualAssigned(shift.assigment_types)
    const isPendingAssignment = shift.assigment_types === 'individual-assigned';

    return (
        <CardContent sx={{ padding: '0 10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: '3px 0', backgroundColor: theme.palette.surfaceContainerLow.main }}>
                <M3TitleMedium variant="subtitle1" component="div" sx={{ width: '400px', padding: 1.5 }}>
                    ASSIGNED TO
                </M3TitleMedium>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 1, paddingBottom: '30px' }}>
                    <CustomAvatar>
                        {renderAssignmentIcon(shift.assigment_types)}
                    </CustomAvatar>
                    <M3LabelMedium variant="caption" component="div">
                        {assignmentIconText[shift.assigment_types]}
                    </M3LabelMedium>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '18px' }}>
                    {
                        isIndividual ?
                            <>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 1 }}>
                                    <CustomAvatar
                                        {...stringAvatar(
                                            shift.educator.first_name + ' ' + shift.educator.last_name
                                        )}
                                        src={
                                            shift.educator.avatar_url !== null
                                                ? shift.educator.avatar_url
                                                : null
                                        }
                                    />
                                </Box>
                                <M3TitleMedium variant="body1" component="div" sx={{ width: '150px', wordBreak: 'break-all' }}>
                                    {shift.educator.first_name + ' ' + shift.educator.last_name}
                                </M3TitleMedium>
                                <Chip sx={{ marginLeft: 5, color: 'white' }}
                                    label={isPendingAssignment ? 'Pending' : 'Declined'}
                                    color={isPendingAssignment ? 'success' : 'error'} />
                            </>
                            : <M3TitleMedium variant="body1" component="div" sx={{ width: '310px' }}>
                                {
                                    assignmentSessionText[shift.assigment_types]
                                }
                            </M3TitleMedium>
                    }
                </Box>
            </Box>
        </CardContent>
    )
}
export default AssignmentItem;